import { Component, OnInit, Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'  // <- ADD THIS
})


export class User {
  firstName: string;
  lastName: string;
  email: string;
  customerId: string;
  userId: string;
  aspnetuserId: string;
  userRoleId: string;
  tokenValue: string;
  name: string;
  normalizedName: string;
  status: boolean;
  isSuperAdmin: boolean;
}

export class CustomerStatus {
  status: string;
  statusmsg: string;
}

export class GetScanList {
  rowNo: string;
  scanningId: string;
  scanStart: string;
  scanEnd: string;
  scanningStatus: string;
  scanningType: string;
  subscriptionName: string;
  subscriptiontype: string;
  customerName: string;
  companyName: string;
  complianceName: string;
  noControlsFailed: number;
  noControlsPassed: number;
  noControlsScanned: number;
  scanningDate: Date;
}

export class AuditLogs {
  action: string;
  userid: string;
  auditlog: string;
  customerid: string;
}



export class credentials {
  Email: string;
  Password: string;
}

export class ResetModel {
  status: string;
  error: string;
}

export class ResetpwdModel {
  Email: string;
  Password: string;
}


export class UserLogin {
  customerId: string;
  userId: string;
  userRoleId: string;
  firstName: string;
  lastName: string;
  email: string;
  aspnetuserId: string;
}

export class UserLoginOTP {
  userId: string;
  Otp: string;
  ClientIPAddress: string;
}
export class IndustryDetails {
  Id: string;
  Name: string;
}


export class Customer {
  customerId: string;
  customerName: string;
  customerType: string;
  signedDate: Date;
  industry: number;
  email: string;
  companyName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  postalCode: string;
  status: boolean;
}




export class ForgetPassword {
  Email: string;
}

export class Resetpwd {
  Email: string;
  Password: string;
  ConfirmPassword: string;
}


export class Register_val {
  CustomerName: string;
  Password: string;
  ConfirmPassword: string;
  TimeZone: string;
  PhoneNumber: string;
  LicenseId: number;
  Industry: number;
  Email: string;
}

export class CustomAuditLogs {

  auditlogId: number;
  action: string;
  userId: string;
  auditlog: string;
  auditTime: Date;
  customerId: string;
  companyName: string;
  firstName: string;
  email: string;
  custAuditTime: string;
}

export class AuditLogsPaginationSettings {
  totalcount: number;
  totalPages: number;
}

//export class IndustryDetails {
//  Id: string;
//  Name: string;
//}

export class LicenseDetails {
  licenseId: string;
  licenseName: string;
}

export class ResetPassword {
  Password: string;
  ConfirmPassword: string;
  Email: string;
  CurrentPassword: string;
}

export class CResendOtp {
  AspnetUserId: string;
}


export class TimeZoneList {
  timeZoneId: number;
  timeZone: string;
  UTC: string;
}

export class FeatureState {
  FeatureId: string;
  Name: string;
  BitValue: number;
}


