
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
//import { API_CONFIG, AppConfig, APP_CONFIG } from '../../config/app.config';
import { SubscriptionCompliance, SubscriptionScan, ScanDetailsForControls, SubscriptionStatus, ActivityLog, SubscriptionDetails, StorageAccounts, NotificationModel } from '../../model/subscriptionCompliance.model'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { strict } from 'assert';
import { SyncAsync } from '@angular/compiler/src/util';
import { Router } from '@angular/router';
import { ParamsComplianceQumuliScore, ComplianceQumuliScore, DashboardDataCls } from '../../model/reports';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { Providermaster } from '../../model/providermaster';
import { AppConfigService, AppConfig} from '../../config/AppConfig.config';
import {  GetEventSubscriptionList } from '../../model/compliancestandard';
import { IcamLog, IcamLogData, WAFInfo } from 'src/app/model/icam.model';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionScanService {

  private config: AppConfig;

  constructor(private http: HttpClient, private router: Router, private apiService: ApiService, private appConfigService: AppConfigService) {
    this.config = appConfigService.appConfig;
  }

  GetEventsubscription(): Observable<GetEventSubscriptionList[]> {
    let headersVal = new HttpHeaders({ 'Authorization': 'Bearer ' + sessionStorage.getItem("Scusttokenval") });
    return this.apiService.get(this.config.EventsSubscription + "/" + sessionStorage.getItem("Scustomerid"))
      .pipe(map(
        data => data
      ));
  }

  GetIcamsubscription(): Observable<GetEventSubscriptionList[]> {
    let headersVal = new HttpHeaders({ 'Authorization': 'Bearer ' + sessionStorage.getItem("Scusttokenval") });
    return this.apiService.get(this.config.IcamSubscription + "/" + sessionStorage.getItem("Scustomerid"))
      .pipe(map(
        data => data
      ));
  }

  GetSubscriptionDetailsStatus(Scanid: string): Observable<SubscriptionScan[]> {
    let headersVal = new HttpHeaders({ 'Authorization': 'Bearer ' + sessionStorage.getItem("Scusttokenval") });
    return this.apiService.get(this.config.subscriptionscanstatus + Scanid)
      .pipe(map((data => data)));
  }

  GetSubscriptionControlsService(Scanid: string): Observable<ScanDetailsForControls[]> {

    let headersVal = new HttpHeaders({ 'Authorization': 'Bearer ' + sessionStorage.getItem("Scusttokenval") });
    return this.apiService.get(this.config.controlgroupdetails + Scanid + "/" + sessionStorage.getItem("Scustomerid"))
      .pipe(map((data => data)));
  }

  GetSubscriptionsDetailsbyUserid(Userid: string): Observable<any> {

    let headersVal = new HttpHeaders({ 'Authorization': 'Bearer ' + sessionStorage.getItem("Scusttokenval") });
    return this.apiService.get(this.config.GetSubscriptionbyUserid + Userid + "/" + sessionStorage.getItem("sprojectID"))
      .pipe(map((data => data)));
  }



  GetSubscriptionsDetailsbyCustomer(Userid: string): Observable<any> {

    let headersVal = new HttpHeaders({ 'Authorization': 'Bearer ' + sessionStorage.getItem("Scusttokenval") });
    return this.apiService.get(this.config.GetSubscriptionbyCustomerId + Userid)
      .pipe(map((data => data)));
  }

  GetSubscriptionsDetailsAWSbyUserid(Userid: string): Observable<any> {

    let headersVal = new HttpHeaders({ 'Authorization': 'Bearer ' + sessionStorage.getItem("Scusttokenval") });
    return this.apiService.get(this.config.GetSubscriptionAWSbyCustomerId + Userid+"/"+sessionStorage.getItem("Suserid")+"/"+sessionStorage.getItem("sprojectID"))
      .pipe(map((data => data)));
  }

  GetSubscriptionsDetailsGCPbyUserid(Userid: string): Observable<any> {

    let headersVal = new HttpHeaders({ 'Authorization': 'Bearer ' + sessionStorage.getItem("Scusttokenval") });
    return this.apiService.get(this.config.GetSubscriptionGCPbyCustomerId + Userid+"/"+sessionStorage.getItem("Suserid")+"/"+sessionStorage.getItem("sprojectID"))
      .pipe(map((data => data)));
  }

  GetSubscriptionsDetailsM365byUserid(Userid: string): Observable<any> {

    let headersVal = new HttpHeaders({ 'Authorization': 'Bearer ' + sessionStorage.getItem("Scusttokenval") });
    return this.apiService.get(this.config.GetSubscriptionM365byCustomerId + Userid+"/"+sessionStorage.getItem("Suserid")+"/"+sessionStorage.getItem("sprojectID"))
      .pipe(map((data => data)));
  }

  GetSubscriptionScanReportDetails(ScanId: string): Observable<SubscriptionScan[]> {

    let headersVal = new HttpHeaders({ 'Authorization': 'Bearer ' + sessionStorage.getItem("Scusttokenval") });
    return this.apiService.get(this.config.GetSubscriptionScanReport + ScanId)
      .pipe(map((data => data)));
  }


  GetPDFScanReportDetails(ScanId: NotificationModel): Observable<any> {

    let headersVal = new HttpHeaders({ 'Authorization': 'Bearer ' + sessionStorage.getItem("Scusttokenval") });
    return this.apiService.post(this.config.Midpdf , ScanId)
      .pipe(map((data => data)));
  }


  GetCustomerTokenDetails(CustomerId: string): Observable<SubscriptionScan[]> {

    let headersVal = new HttpHeaders({ 'Authorization': 'Bearer ' + sessionStorage.getItem("Scusttokenval") });
    return this.apiService.get(this.config.GetTokenApi + CustomerId)
      .pipe(map((data => data)));
  }

  GetSubscriptionDetailsbyCustomerId(CustomerId: string): Observable<SubscriptionDetails[]> {

    let headersVal = new HttpHeaders({ 'Authorization': 'Bearer ' + sessionStorage.getItem("Scusttokenval") });
    return this.apiService.get(this.config.GetSubscriptionbyCustomerId + CustomerId)
      .pipe(map((data => data)));
  }

  GetSubScanDetailsbySubId(SubscriptionId: string): Observable<SubscriptionScan[]> {

    let headersVal = new HttpHeaders({ 'Authorization': 'Bearer ' + sessionStorage.getItem("Scusttokenval") });
    return this.apiService.get(this.config.GetSubScanbySubId + SubscriptionId)
      .pipe(map((data => data)));
  }


  GetStorageAccounts(SubscriptionId: string): Observable<StorageAccounts[]> {

    let headersVal = new HttpHeaders({ 'Authorization': 'Bearer ' + sessionStorage.getItem("Scusttokenval") });
    return this.apiService.get(this.config.StorageAccounts + SubscriptionId)
      .pipe(map((data => data)));
  }


  CallAzureActivityLogFunction(): Observable<ActivityLog[]> {
    let Scustomerid = sessionStorage.getItem("Scustomerid")
    let subscriptionGUID = sessionStorage.getItem("eventsubscriptionGUID")
    return this.apiService.get(this.config.Azurefeature + "&features=activitylog&Scustomerid=" + Scustomerid + "&SubscriptionGUID=" + subscriptionGUID + "&QumuliToken=" + sessionStorage.getItem("Stoken"))
      .pipe(map((data => data)));
  }

  CallAzureIcamLogFunction(): Observable<IcamLogData[]>{
    let Scustomerid = sessionStorage.getItem("Scustomerid")
    let subscriptionGUID = sessionStorage.getItem("eventsubscriptionGUID")
    return this.apiService.get(this.config.Azurefeature + "&features=icamlog&Scustomerid=" + Scustomerid + "&SubscriptionGUID="+ subscriptionGUID + "&QumuliToken=" + sessionStorage.getItem("Stoken"))
      .pipe(map((data => data)));
  }

  CallAzureWafLogFunction(): Observable<WAFInfo[]> {
    let Scustomerid = sessionStorage.getItem("Scustomerid")
    let subscriptionGUID = sessionStorage.getItem("eventsubscriptionGUID")
    return this.apiService.get(this.config.Azurefeature + "&features=waf&Scustomerid=" + Scustomerid + "&SubscriptionGUID=" + subscriptionGUID + "&QumuliToken=" + sessionStorage.getItem("Stoken"))
      .pipe(map((data => data)));
  }



  CallAzureFunction(ScanningId: string, ComplianceId: number, SubscriptionId: number) {
    return this.apiService.get(this.config.Azurefunction + "&ScanningId=" + ScanningId + " &ComplianceId=" + ComplianceId + " &QumuliSubscriptionId=" + SubscriptionId + "&QumuliToken=" + sessionStorage.getItem("Stoken"))
      .pipe(map((data => data)));
  }


  CallAWSFunction(ScanningId: string, ComplianceId: number, SubscriptionId: number) {

    return this.apiService.get(this.config.AWSfunction + "&ScanningId=" + ScanningId + " &ComplianceId=" + ComplianceId + " &QumuliSubscriptionId=" + SubscriptionId + "&QumuliToken=" + sessionStorage.getItem("Stoken"))
      .pipe(map((data => data)));
  }
  //Deepa : 804
  GetSubscriptionsDetailsbyCustomerWithAuthId(Userid: string): Observable<any> {

    let headersVal = new HttpHeaders({ 'Authorization': 'Bearer ' + sessionStorage.getItem("Scusttokenval") });
    return this.apiService.get(this.config.GetSubscriptionbyCustomerIdwithAuthId + Userid + "/" + sessionStorage.getItem("AuthID") )
      .pipe(map((data => data)));
  }
  ValidateUser(Userid: string): Observable<any> {

    return this.apiService.get(this.config.validateUser + Userid )
      .pipe(map((data => data)));
  }
}
