import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Providermaster } from '../model/providermaster';
import { Router } from '@angular/router';
import { AzureADService } from '../service/childservice/azureAD.service';
import { EventEmitterService, ContinuousService, CustomerSettings, CustomerLicense } from '../service/childservice';

export class RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  role: string;
}

export class RouteSubInfo {
  path: string;
  title: string;
  icon: string;
  class: string;

}




export const ROUTES: RouteInfo[] = [
  { path: '/compliance/dashboard', title: 'Dashboard', icon: 'fa-home', class: 'nav-item', role: 'admin' },
  { path: '/compliance/providermaster', title: 'Scan', icon: 'fa-radar', class: '1', role: 'admin' },
  { path: '/compliance/users', title: 'Users', icon: 'fad fa-users', class: 'nav-item', role: 'admin' },
  //{ path: '/compliance/customers', title: 'Customers', icon: 'far fa-house-user', class: 'nav-item', role: 'admin' },
  //{ path: '/compliance/customcompliance', title: 'Compliance', icon: 'fad fa-tasks-alt', class: 'nav-item', role: 'admin' },
  { path: '/compliance/reports', title: 'Reports', icon: 'fa-tachometer-alt', class: 'nav-item', role: 'admin' },

];


export const SecOps: RouteInfo[] = [
  { path: '/compliance/dashboard', title: 'Dashboard', icon: 'fa-home', class: 'nav-item', role: 'admin' },
  { path: '/compliance/providermaster', title: 'Scan', icon: 'fa-radar', class: '1', role: 'admin' },  
  //{ path: '/compliance/customers', title: 'Customers', icon: 'far fa-house-user', class: 'nav-item', role: 'admin' },
  //{ path: '/compliance/customcompliance', title: 'Compliance', icon: 'fad fa-tasks-alt', class: 'nav-item', role: 'admin' },
  { path: '/compliance/reports', title: 'Reports', icon: 'fa-tachometer-alt', class: 'nav-item', role: 'admin' },

];


export const ReportOperator	: RouteInfo[] = [
  { path: '/compliance/dashboard', title: 'Dashboard', icon: 'fa-home', class: 'nav-item', role: 'admin' },
  { path: '/compliance/providermaster', title: 'Scan', icon: 'fa-radar', class: '1', role: 'admin' },
  //{ path: '/compliance/customers', title: 'Customers', icon: 'far fa-house-user', class: 'nav-item', role: 'admin' },
  //{ path: '/compliance/customcompliance', title: 'Compliance', icon: 'fad fa-tasks-alt', class: 'nav-item', role: 'admin' },
  { path: '/compliance/reports', title: 'Reports', icon: 'fa-tachometer-alt', class: 'nav-item', role: 'admin' },

];


@Component({
  //moduleId: module.id,
  selector: 'psidebar-cmp',
  templateUrl: 'psidebar.component.html',
})

export class PSidebarComponent implements OnInit {
  public menuItems: any[];
  public mngClass: string;
  public parentClass: string;
  public provider_master: Providermaster[];
  showScanmenu: boolean;
  showCustomermenu: boolean = false;
  showUserCreation: boolean;
  customerLicense: CustomerLicense[];

  constructor(private continuousService: ContinuousService,private eventEmitterService: EventEmitterService, private http: HttpClient, private router: Router, private azureADService: AzureADService) {
    this.provider_master = [];
    this.customerLicense = [];
    this.parentClass = "nav-item dropdown"
    this.mngClass = "dropdown-menu";
    this.showCustomermenu = false;
    this.showScanmenu = false;
    this.showUserCreation = false;
    this.GetProviders();
    this.RoleValidation();
  }

  ngOnInit() {
    this.RoleValidation();
  }

  RoleValidation() {
    this.azureADService.GetAspNetRoles().subscribe(result => {

      sessionStorage.setItem("userRole", result.name);
      this.showUserCreation = false;

      if (result.name == "Admin") {
        this.showUserCreation = true;
      }

      if (result.name == "Admin" || result.name == "SecOps") {
        this.showScanmenu = true;
        this.showCustomermenu = false;
        this.showUserCreation = false;
        this.GetProviders();

      }

      else {
        if (result.name == "ReportOperator") {
          this.showCustomermenu = false;
          this.showScanmenu = false;
          this.provider_master = [];
          this.menuItems = ReportOperator.filter(menuItem => menuItem);
        }
        else {
          this.showCustomermenu = true;
        }


      }

    });
  }


  ShowMenu() {

    if (this.mngClass == "dropdown-menu") {
      this.parentClass = "nav-item dropdown show"
      this.mngClass = "dropdown-menu show";
    }
    else {
      this.parentClass = "nav-item dropdown"
      this.mngClass = "dropdown-menu";
    }

  }




  GetProvidermaster(providermaster: Providermaster) {

    if (sessionStorage.getItem("userRole") == "Admin" || sessionStorage.getItem("userRole") == "SecOps") {
      sessionStorage.setItem("sprovidermasterCloudId", providermaster.cloudId);
      // this.eventEmitterService.onFirstComponentButtonClick();    

      if (providermaster.cloudProviderName == "AWS")
        this.router.navigate(['/compliance/aprovidermaster']);
      else if (providermaster.cloudProviderName == "Azure")
        this.router.navigate(['/compliance/providermaster']);
      else if (providermaster.cloudProviderName == "GCP")
        this.router.navigate(['/compliance/gprovidermaster']);
      else if (providermaster.cloudProviderName == "Oracle")
        this.router.navigate(['/compliance/oprovidermaster']);
      else if (providermaster.cloudProviderName == "Microsoft 365")
        this.router.navigate(['/compliance/ms365provider']);
      else
        this.router.navigate(['/compliance/oprovidermaster']);
    }
    else {
      this.router.navigate(['']);
    }


  }


  GetProviders() {

    try {

      this.continuousService.GetCustomerLicense().subscribe(data => {

        this.customerLicense = data;
      });

    } catch (e) {
      //this.logService.LogError(e, "", this.PageName);
    }

    this.azureADService.GetProviderMaster().subscribe(result => {
      this.provider_master = result;

      if (sessionStorage.getItem("userRole") == "SecOps") {
        this.menuItems = SecOps.filter(menuItem => menuItem);
      }
      else {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
      }


    });


    //let tokenValue: string;
    //this.http.get('https://localhost:44370/api/CustomerTokenDetails/GetCustomerTokenDetailsByCustomerId/' + sessionStorage.getItem("Scustomerid")).subscribe(
    //  datagettokenval => {
    //    tokenValue = datagettokenval[0]["tokenValue"];
    //    const headersVal = new HttpHeaders({ 'Authorization': 'Bearer ' + tokenValue });

    //    this.http.get<Providermaster[]>('https://localhost:44370/api/ProviderMasters', { headers: headersVal }).subscribe(result => {
    //      this.provider_master = result;

    //    }, error => console.error(error));

    //  }, error => console.error(error));
  }


}
