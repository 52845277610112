import { Injectable, Inject } from '@angular/core';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { ApiService } from './api.service';
import { JiraPost, JiraPostResult } from '../../model/JiraPost.model';
import { AppConfig, AppConfigService } from '../../config/AppConfig.config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class JiraIntegrationService {
  private config: AppConfig;

  constructor(private apiService: ApiService, private appConfigService: AppConfigService) {
    this.config = appConfigService.appConfig;

  }

  CreateJiraTaskCreation(oJiraPost: JiraPost): Observable<JiraPostResult> {
    return this.apiService.post(this.config.JiraIssueCreation, oJiraPost)
      .pipe(map(
        data => data
      ));
  }


  UpdateJiraTickets(oJiraPost: RequestJiratickets): Observable<any> {
    return this.apiService.post(this.config.JiraUpdatetTicket, oJiraPost)
      .pipe(map(
        data => data
      ));
  }


  GetstatusJiraTickets(oJiraPost: JiraStatus): Observable<any> {
    return this.apiService.post(this.config.JiraStatusTicket, oJiraPost)
      .pipe(map(
        data => data
      ));
  }

}


export class RequestJiratickets {
  ScanControlId: string;
  JiraTicketId: string;
}


export class JiraStatus {
  TicketId: string;
  Url: string;
  Code: string;
  token: string;
}





