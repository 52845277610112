import { AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Providermaster } from '../model/providermaster';
import { Router } from '@angular/router';
import { AzureADService } from '../service/childservice/azureAD.service';
import { EventEmitterService, ContinuousService, CustomerSettings, CustomerLicense, ChildmenuService } from '../service/childservice';
import { UserIdleService } from 'angular-user-idle';
import { ProvidermasterComponent } from '../pages/providermaster/providermaster.component';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { LoginService } from '../service/childservice/login.service';

export class RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  role: string;
}

export class RouteSubInfo {
  path: string;
  title: string;
  icon: string;
  class: string;

}

///compliance/continuous<i class="fas fa-cog"></i>


export const Superadmin: RouteInfo[] = [
  { path: '/compliance/dashboard', title: 'Dashboard', icon: 'fa-home', class: '2', role: 'admin' },
  { path: '/compliance/provider', title: 'Compliance', icon: 'fa-radar', class: '1', role: 'admin' },
  { path: '/compliance/projects', title: 'Projects', icon: 'fad fa-diamond', class: 'nav-item', role: 'admin' },
  { path: '/compliance/tools', title: 'Tools', icon: 'fad fa-wrench', class: 'nav-item', role: 'admin' },
  { path: '/compliance/icam', title: 'ICAM', icon: 'fa-solid fa-key', class: 'nav-item', role: 'admin' },
  { path: '/compliance/assets', title: 'Assets', icon: 'fad fa-chart-network', class: 'nav-item', role: 'admin' },
  { path: '/compliance/settings', title: 'Settings', icon: 'fas fa-cog', class: 'nav-item', role: 'admin' },
  { path: '/compliance/reports', title: 'Reports', icon: 'fad fa-file-chart-line', class: 'nav-item', role: 'admin' },
  { path: '/compliance/userlogs', title: 'Audit Logs', icon: 'fad fa-clipboard-list', class: 'nav-item', role: 'admin' },
];



export const ROUTES: RouteInfo[] = [
  { path: '/compliance/dashboard', title: 'Dashboard', icon: 'fa-home', class: '2', role: 'admin' },
  { path: '/compliance/provider', title: 'Compliance', icon: 'fa-radar', class: '1', role: 'admin' },
  { path: '/compliance/tools', title: 'Tools', icon: 'fad fa-wrench', class: 'nav-item', role: 'admin' },
 // { path: '/compliance/projects', title: 'Projects', icon: 'fad fa-diamond', class: 'nav-item', role: 'admin' },
 // { path: '/compliance/icam', title: 'ICAM', icon: 'fa-solid fa-key', class: 'nav-item', role: 'admin' },
  //{ path: '/compliance/events', title: 'Events', icon: 'fad fa-clipboard', class: 'nav-item', role: 'admin' },
  { path: '/compliance/assets', title: 'Assets', icon: 'fad fa-chart-network', class: 'nav-item', role: 'admin' },
  
  { path: '/compliance/settings', title: 'Settings', icon: 'fas fa-cog', class: 'nav-item', role: 'admin' },  
  { path: '/compliance/reports', title: 'Reports', icon: 'fad fa-file-chart-line', class: 'nav-item', role: 'admin' },
  { path: '/compliance/userlogs', title: 'Audit Logs', icon: 'fad fa-clipboard-list', class: 'nav-item', role: 'admin' },
];


export const SecOps: RouteInfo[] = [
  { path: '/compliance/dashboard', title: 'Dashboard', icon: 'fa-home', class: '2', role: 'admin' },
  { path: '/compliance/provider', title: 'Compliance', icon: 'fa-radar', class: '1', role: 'admin' },
  { path: '/compliance/assets', title: 'Assets', icon: 'fad fa-chart-network', class: 'nav-item', role: 'admin' },
  { path: '/compliance/reports', title: 'Reports', icon: 'fa-tachometer-alt', class: 'nav-item', role: 'admin' },


];


export const ReportOperator	: RouteInfo[] = [
  { path: '/compliance/dashboard', title: 'Dashboard', icon: 'fa-home', class: '2', role: 'admin' },
  { path: '/compliance/provider', title: 'Compliance', icon: 'fa-radar', class: '1', role: 'admin' },
  { path: '/compliance/', title: 'Assets', icon: 'fad fa-chart-network', class: 'nav-item', role: 'admin' },

  //{ path: '/compliance/customers', title: 'Customers', icon: 'far fa-house-user', class: 'nav-item', role: 'admin' },
  //{ path: '/compliance/customcompliance', title: 'Compliance', icon: 'fad fa-tasks-alt', class: 'nav-item', role: 'admin' },
  { path: '/compliance/reports', title: 'Reports', icon: 'fa-tachometer-alt', class: 'nav-item', role: 'admin' },

];


@Component({
  //moduleId: module.id,
  selector: 'sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit,AfterViewInit{
  public menuItems: any[];
  public mngClass: string;
  public parentClass: string;
  public provider_master: Providermaster[];
  showScanmenu: boolean;
  showCustomermenu: boolean = false;
  bIsSuperadmin: boolean = false;

  showUserCreation: boolean;
  bRoleMonitoring: boolean;
  bRoleQumuliMaster: boolean;
  customerLicense: CustomerLicense[];
  count: number;


  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';

  @ViewChild('childModal', { static: false }) childModal: TemplateRef<any>;
  @ViewChild('content', { static: false }) content: TemplateRef<any>;
  @ViewChild('popupSessionout', { static: true }) popupSessionout: TemplateRef<any>;
  //@Input() Providermaster: ProvidermasterComponent;

  constructor(private modalService: NgbModal, private childmenuService: ChildmenuService, private loginService: LoginService, private continuousService: ContinuousService, private idle: Idle, private keepalive: Keepalive,  private eventEmitterService: EventEmitterService, private http: HttpClient, private router: Router, private azureADService: AzureADService) {
    this.provider_master = [];
    this.customerLicense = [];
    this.parentClass = "nav-item dropdown"
    this.mngClass = "dropdown-menu";
    this.showCustomermenu = false;
    this.showScanmenu = false;
    this.bRoleMonitoring = false;
    this.showUserCreation = false;
    this.bRoleQumuliMaster = false;
    this.bIsSuperadmin = false;

    this.GetProviders();
    this.RoleValidation();

    //this.open(this.childModal);
    //this.open(this.content);

    idle.setIdle(600);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(10);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.modalService.dismissAll();
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);
      this.router.navigate(['/login']);
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      console.log(this.idleState);
      this.open(this.popupSessionout);

    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'Your Qumuli session will be logged out in ' + countdown + ' seconds!'
      console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

        idle.watch()
        this.timedOut = false;


  }

  GoDashBoard() {
    this.router.navigate(['/compliance/dashboard']);
  }


  logout() {
    this.router.navigate(['/login']);
  }

  reset() {
    this.idle.watch();
    //xthis.idleState = 'Started.';
    this.timedOut = false;
  }

  hideChildModal(): void {
   // this.childModal.hide();
    this.modalService.dismissAll();
  }

  stay() {

    this.modalService.dismissAll();
    this.reset();
  }


  popup() {
    this.open(this.popupSessionout);
  }

  ngOnInit() {
    this.RoleValidation();




    // Start watch when time is up.
    //this.userIdle.onTimeout().subscribe(() => { this.router.navigate(['/login']); });



      //this.childmenuService.count.subscribe(c => {
      //  this.count = c;
      //});

  }

  ngAfterViewInit() {



    // this.message = this.child.message
  }


  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      //this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }



  RoleValidation() { 
    this.azureADService.GetAspNetRoles().subscribe(result => {




      sessionStorage.setItem("userRole", result.name);
      this.showUserCreation = false;
      this.bIsSuperadmin = false;
      if (sessionStorage.getItem("SisSuerAdmin") !=null){
      if (sessionStorage.getItem("SisSuerAdmin").toString() == "true") {
        this.bIsSuperadmin = true;        
      }
    }


      if (result.name === "Admin" && this.bIsSuperadmin == true) {
        this.menuItems = Superadmin.filter(menuItem => menuItem);
      }
      else if (result.name === "Admin" || result.name === "SecOps" && this.bIsSuperadmin == false) {
        this.showScanmenu = true;
        this.showCustomermenu = false;
        this.showUserCreation = false;
        this.GetProviders();
      }

      else {
        if (result.name === "ReportOperator") {
          this.showCustomermenu = false;
          this.showScanmenu = false;
          this.provider_master = [];
          this.menuItems = ReportOperator.filter(menuItem => menuItem);
        }      
        else {

          this.showCustomermenu = true;
          this.bRoleQumuliMaster = true;
          this.bRoleMonitoring = true;
          if (result.name === "Monitoring") {            
            this.bRoleQumuliMaster = false;

          }
        }


      }



       
    });
  }





  ShowMenu() {

    if (this.mngClass === "dropdown-menu") {
      this.parentClass = "nav-item dropdown show"
      this.mngClass = "dropdown-menu show";
    }
    else {
      this.parentClass = "nav-item dropdown"
      this.mngClass = "dropdown-menu";
    }

  }




  GetProvidermaster(providermaster: Providermaster) {
//debugger;
    if (sessionStorage.getItem("userRole") === "Admin" || sessionStorage.getItem("userRole") === "SecOps") {
      sessionStorage.setItem("sprovidermasterCloudId", providermaster.cloudId);
      // this.eventEmitterService.onFirstComponentButtonClick();

      //this.Providermaster.GetComplianceStandard();
      this.router.navigate(['/compliance/provider', providermaster.cloudProviderName]);

     // this.router.navigate(['/compliance/providers', providermaster.cloudProviderName]);
     this.childmenuService.nextCount();
      //this.router.navigate(['/compliance/provider', providermaster.cloudProviderName]);
      //if (providermaster.cloudProviderName === "AWS")
      //  this.router.navigate(['/compliance/aprovidermaster']);
      //else if (providermaster.cloudProviderName === "Azure")
      //  this.router.navigate(['/compliance/providermaster']);
      //else if (providermaster.cloudProviderName === "GCP")
      //  this.router.navigate(['/compliance/gprovidermaster']);
      //else if (providermaster.cloudProviderName === "Oracle")
      //  this.router.navigate(['/compliance/oprovidermaster']);
      ////else if (providermaster.cloudProviderName === "Microsoft 365")
      //else if (providermaster.cloudProviderName === "M365")
      //  this.router.navigate(['/compliance/ms365providermaster']);
      //else
      //  this.router.navigate(['/compliance/ms365providermaster']);
    }
    else {
     // this.router.navigate(['/compliance/dashboard']);
    }


  }


  GetProviders() {

    try {

      this.continuousService.GetCustomerLicense().subscribe(data => {

        this.customerLicense = data;
      });

    } catch (e) {

    }

    this.azureADService.GetProviderMaster().subscribe(result => {
      this.provider_master = result;

      if (sessionStorage.getItem("userRole") === "SecOps") {
        this.menuItems = SecOps.filter(menuItem => menuItem);
      }
      else {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
      }


    });


    //let tokenValue: string;
    //this.http.get('https://localhost:44370/api/CustomerTokenDetails/GetCustomerTokenDetailsByCustomerId/' + sessionStorage.getItem("Scustomerid")).subscribe(
    //  datagettokenval => {
    //    tokenValue = datagettokenval[0]["tokenValue"];
    //    const headersVal = new HttpHeaders({ 'Authorization': 'Bearer ' + tokenValue });

    //    this.http.get<Providermaster[]>('https://localhost:44370/api/ProviderMasters', { headers: headersVal }).subscribe(result => {
    //      this.provider_master = result;

    //    }, error => console.error(error));

    //  }, error => console.error(error));
  }


}
