import { Routes } from '@angular/router';
import { EditprofileLayoutComponent } from './layouts/editprofile-layout/editprofile-layout.component';
import { AssetsLayoutComponent } from './layouts/assets-layout/assets-layout.component';
import { LegalComponent } from './homepages/legal/legal.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { ProfileLayoutComponent } from './layouts/profile-layout/profile-layout.component';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { SuperLayoutComponent } from './layouts/super-layout/super-layout.component';
import { SecurityInformationComponent } from './securityinformation/securityinformation.component';
import { GraphSecondStepComponent } from './pages/graph-second-step/graph-second-step.component';
import { RegisterLayoutComponent } from './layouts/register-layout/register-layout.component';
import { AppComponent } from './app.component';
import { AuthGuard } from '../app/service/childservice/authGuard.service';
import { AuthorizationLayoutComponent } from './layouts/authorization-layout/authorization-layout.component';
import { SupportComponent } from './landingpages/support/support.component';
import { SupportLayoutComponent } from './layouts/support-layout/support-layout.component';


export const AppRoutes: Routes = [
  {
    path: 'legal', component: LegalComponent, data: {
      breadcrumb: 'Legal',
    },
  },
  {
    path: 'support', component: SupportLayoutComponent, children: [
      {
        path: '',
        loadChildren: './layouts/support-layout/support-layout.module#SupportLayoutModule'
      }]
  },
  {
    path: 'privacy', component: LegalComponent, data: {
      breadcrumb: 'privacy',
    },
  },
  {
    path: 'marketplace',
    component: RegisterLayoutComponent, 
    children: [
      {
        path: '',
        loadChildren: './layouts/register-layout/register-layout.module#RegisterLayoutModule'
      }]
  },

  {
    path: 'azure-security',
    component: SecurityInformationComponent
    
  },
  {
    path: 'assets',
    component: AssetsLayoutComponent, canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: './layouts/assets-layout/assets-layout.module#AssetsLayoutModule'
      }]
  },
  {
    path: 'compliance',
    component: AdminLayoutComponent, canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  {
    path: 'profile',
    component: ProfileLayoutComponent, 
    children: [
      {
        path: '',
        loadChildren: './layouts/profile-layout/profile-layout.module#ProfileLayoutModule'
      }]
  },
  {
    path: 'editprofile',
    component: EditprofileLayoutComponent, canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: './layouts/editprofile-layout/editprofile-layout.module#EditprofileLayoutModule'
      }]
  },
  {
    path: 'qumuliadmin',
    component: SuperLayoutComponent, canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: './layouts/super-layout/super-layout.module#SuperLayoutModule'
      }]
  },
  {
    path: 'bo',
    component: AuthorizationLayoutComponent, canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: './layouts/authorization-layout/authorization-layout.module#AuthorizationLayoutModule'
      }]
  },
  {
    path: 'login',
    component: HomeLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './layouts/home-layout/home-layout.module#HomeLayoutModule'
      }]
  },
  {
    path: 'trial',    
    children: [
      {
        path: '', 
        redirectTo: '/login/trial', pathMatch: 'full'
      
      }]
  },

  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
]
