
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
//import { API_CONFIG, AppConfig, APP_CONFIG } from '../../config/app.config';
import { SubscriptionCompliance, SubscriptionScan, ScanDetailsForControls, SubscriptionStatus, SubscriptionDetails } from '../../model/subscriptionCompliance.model'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { strict } from 'assert';
import { SyncAsync } from '@angular/compiler/src/util';
import { Router } from '@angular/router';
import { ParamsComplianceQumuliScore, ComplianceQumuliScore, DashboardDataCls, DashboardDataNumberOfScan, DashboardAssetLevelCompliance, ComplianceBasedData, DashboardCompliance, GetAnalysisAssets, GetAnalysisAssetsdrilldown, ParamsQumuliScore, GetAnalysisAssetsdrilldowninfo, ParamsQumuliPlatform, ParamsCloud, NetworkInventoryCount, NetworkInventoryRpt, NetworkInventoryRptUDR, GetDashboard_cloud, ParamsQumuliSubscription, GetDashboard_Compliance_Subscription, GetDashboard_Cloud_Compliance } from '../../model/reports';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { AppConfigService, AppConfig } from '../../config/AppConfig.config';
import { pGetSubscriptionAssets } from './assets.service';
import { EmailStatus, ParamsFeedback, ParamsSupport } from '../../shared/navbar/navbar.component';
import { ProjectDetails, ProjectsList } from 'src/app/model/compliancestandard';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private config: AppConfig;
 
  constructor(private http: HttpClient, private router: Router, private apiService: ApiService, private appConfigService: AppConfigService) {
    this.config = appConfigService.appConfig;

  }//, @Inject(APP_CONFIG) private config: AppConfig

  public GetReport(paramsComplianceQumuliScore: ParamsComplianceQumuliScore): Observable<ComplianceQumuliScore[]>{   
    const headersVal = new HttpHeaders({ 'Authorization': 'Bearer ' + sessionStorage.getItem("Scusttokenval") });
    return this.http.post<ComplianceQumuliScore[]>(this.config.reports, paramsComplianceQumuliScore, { headers: headersVal });

  }


  DashboardComplianceReport(paramsComplianceQumuliScore1: ParamsComplianceQumuliScore): Observable<DashboardCompliance[]> {
    paramsComplianceQumuliScore1.UserId = sessionStorage.getItem("Suserid");
    paramsComplianceQumuliScore1.ProjectId = sessionStorage.getItem("sprojectID");
    return this.apiService.post(this.config.DashboardCompliance, paramsComplianceQumuliScore1)
      .pipe(map((data => data)));
  }

  ToppanelAssetsReport(paramsCloud: ParamsCloud): Observable<pGetSubscriptionAssets[]> {

    return this.apiService.post(this.config.CloudAssets, paramsCloud)
      .pipe(map((data => data)));
  }

  SendSupportTeam(paramsSupport: ParamsSupport): Observable<EmailStatus> {

    return this.apiService.post(this.config.SupportMail, paramsSupport)
      .pipe(map((data => data)));
  }


  SendFeedback(paramsFeedback: ParamsFeedback): Observable<EmailStatus> {

    return this.apiService.post(this.config.FeedbackMail, paramsFeedback)
      .pipe(map((data => data)));
  }


  GetDashBoardReport(paramsComplianceQumuliScore1: ParamsComplianceQumuliScore): Observable<DashboardDataCls[]> {
    paramsComplianceQumuliScore1.ProjectId = sessionStorage.getItem("sprojectID");
    return this.apiService.post(this.config.dashbaordtoppanel, paramsComplianceQumuliScore1)
      .pipe(map((data => data)));
  }

  DashboardDataNumberOfScan(paramsComplianceQumuliScore1: ParamsComplianceQumuliScore): Observable<DashboardDataNumberOfScan[]> {
 
    return this.apiService.post(this.config.dashboardDataNumberOfScan, paramsComplianceQumuliScore1)
      .pipe(map((data => data)));
  }


  Dashboard_cloud(paramsComplianceQumuliScore1: ParamsComplianceQumuliScore): Observable<GetDashboard_cloud[]> {  
    paramsComplianceQumuliScore1.ProjectId = sessionStorage.getItem("sprojectID");
    return this.apiService.post(this.config.GetDashboard_cloud, paramsComplianceQumuliScore1)
      .pipe(map((data => data)));
  }


  Dashboard_Cloud_Compliance(ParamsQumuliPlatform1: ParamsQumuliPlatform): Observable<GetDashboard_Cloud_Compliance[]> {

    return this.apiService.post(this.config.GetDashboard_Cloud_Compliance, ParamsQumuliPlatform1)
      .pipe(map((data => data)));
  }


  Dashboard_Compliance_Subscription(ParamsQumuiSubscription1: ParamsQumuliSubscription): Observable<GetDashboard_Compliance_Subscription[]> {

    return this.apiService.post(this.config.GetDashboard_Compliance_Subscription, ParamsQumuiSubscription1)
      .pipe(map((data => data)));
  }



  DashboardPlatformAssets(paramsQumuliPlatform: ParamsQumuliPlatform): Observable<GetAnalysisAssets[]>
  {
    paramsQumuliPlatform.UserId = sessionStorage.getItem("Suserid");
    paramsQumuliPlatform.ProjectId = sessionStorage.getItem("sprojectID");
    return this.apiService.post(this.config.DashboardAnalysisPlatform, paramsQumuliPlatform)
      .pipe(map((data => data)));
  }
  DashboardProjects(): Observable<ProjectsList[]>
  {
    return this.apiService.get(this.config.listProjects+ "GetAllProjectsForUser/"+sessionStorage.getItem("Scustomerid")+"/"+sessionStorage.getItem("Suserid"))
      .pipe(map((data => data)));
  }
  ProjectDetails(projId:string): Observable<ProjectDetails[]>
  {
    return this.apiService.get(this.config.listProjects+ "GetProjectDetails/"+sessionStorage.getItem("Suserid")+"/"+projId+"/t")
      .pipe(map((data => data)));
  }
  

  DashboardAnalysisAssets(paramsComplianceQumuliScore1: ParamsQumuliPlatform): Observable<GetAnalysisAssets[]> {

    return this.apiService.post(this.config.DashboardAnalysisAssets, paramsComplianceQumuliScore1)
      .pipe(map((data => data)));
  }


  DashboardAnalysisAssetsdrilldown(paramsComplianceQumuliScore1: ParamsQumuliScore): Observable<GetAnalysisAssetsdrilldown[]> {

    return this.apiService.post(this.config.DashboardAnalysisAssetsdrilldown, paramsComplianceQumuliScore1)
      .pipe(map((data => data)));
  }

  DashboardAnalysisAssetsdrilldowninfo(paramsComplianceQumuliScore1: ParamsQumuliScore): Observable<GetAnalysisAssetsdrilldowninfo[]> {

    return this.apiService.post(this.config.GetAnalysisAssets_Drilldowninfo, paramsComplianceQumuliScore1)
      .pipe(map((data => data)));
  }

    DashboardAssetLevelCompliance(paramsComplianceQumuliScore1: ParamsComplianceQumuliScore): Observable<DashboardAssetLevelCompliance[]> {
       
        return this.apiService.post(this.config.dashboardAssetLevelCompliance, paramsComplianceQumuliScore1)
            .pipe(map((data => data)));
    }

    DashboardMonthWisePassVsFail(paramsComplianceQumuliScore1: ParamsComplianceQumuliScore): Observable<ComplianceBasedData[]> {
      paramsComplianceQumuliScore1.ProjectId = sessionStorage.getItem("sprojectID");
        return this.apiService.post(this.config.dashboardMonthWisePassVsFail, paramsComplianceQumuliScore1)
            .pipe(map((data => data)));
    }

    DashboardComplianceWised(paramsComplianceQumuliScore1: ParamsComplianceQumuliScore): Observable<ComplianceBasedData[]> {

        return this.apiService.post(this.config.dashboardComplianceWised, paramsComplianceQumuliScore1)
            .pipe(map((data => data)));
  }


  NetworkInventoryCountReport(paramsComplianceQumuliScore1: ParamsComplianceQumuliScore): Observable<NetworkInventoryCount[]> {
    paramsComplianceQumuliScore1.ProjectId = sessionStorage.getItem("sprojectID");
    return this.apiService.post(this.config.NetworkInventoryCountConfig, paramsComplianceQumuliScore1)
      .pipe(map((data => data)));
  }

  NetworkInventoryReport(paramsComplianceQumuliScore1: ParamsComplianceQumuliScore): Observable<NetworkInventoryRpt[]> {

    return this.apiService.post(this.config.NetworkInventoryConfig, paramsComplianceQumuliScore1)
      .pipe(map((data => data)));
  }

  NetworkInventoryReportUDR(paramsComplianceQumuliScore1: ParamsComplianceQumuliScore): Observable<NetworkInventoryRptUDR[]> {
    paramsComplianceQumuliScore1.ProjectId = sessionStorage.getItem("sprojectID");
    paramsComplianceQumuliScore1.UserId = sessionStorage.getItem("Suserid");
    return this.apiService.post(this.config.NetworkInventoryUDRConfig, paramsComplianceQumuliScore1)
      .pipe(map((data => data)));
  }

  NetworkInventoryReportAWSUDR(paramsComplianceQumuliScore1: ParamsComplianceQumuliScore): Observable<NetworkInventoryAWSUDR[]> {

    return this.apiService.post(this.config.DashboardUDR, paramsComplianceQumuliScore1)
      .pipe(map((data => data)));
  }

  NetworkInventoryReportAWSNSG(paramsComplianceQumuliScore1: ParamsComplianceQumuliScore): Observable<NetworkInventoryAWSNSG[]> {

    return this.apiService.post(this.config.DashboardNSG, paramsComplianceQumuliScore1)
      .pipe(map((data => data)));
  }

}

export class NetworkInventoryAWSUDR {
  rowNo: number;
  subscriptionName: string;
  routeTableId: string;
  resourceName: string;
  destinationCidrBlock: string;
  gatewayId: string;

}



export class NetworkInventoryAWSNSG {
  rowNo: number;
  subscriptionName: string;
  securitytype: string;
  ipProtocol: string;
  resourceName: string;
  ipRanges: string;
  fromPort: string;
  toPort: string;
  vpcId: string;
}


