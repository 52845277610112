import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './childservice/api.service';
import { AppConfigService, AppConfig } from '../config/AppConfig.config';
import { AuditLogs,FeatureState, CustomAuditLogs, AuditLogsPaginationSettings } from '../model/user.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  private config: AppConfig;

  public showazureActionButton: boolean;
  public showazureJiraButton: boolean;
  public showawsActionButton: boolean;
  public showawsJiraButton: boolean;
  public showm365ActionButton: boolean;
  public showm365JiraButton: boolean;
  public showgcpActionButton: boolean;
  public showgcpJiraButton: boolean;
  public showContinuous: boolean;

  public License: FeatureState;

  public actionVariable: string;
  public jiraVariable: string;
  public autoscanVariable: string;

  constructor(private http: HttpClient, private apiService: ApiService,
    private appConfigService: AppConfigService) {
    this.config = appConfigService.appConfig;
    this.actionVariable = "D7D79623-D142-4E12-9E85-A2223AE416A1".toLowerCase();
    this.jiraVariable = "6384E56E-3A26-4D09-943C-E6274B24B72E".toLowerCase();
    this.autoscanVariable = "3909FA23-2B6F-4C8D-9DCD-5961E27663B6".toLowerCase();
  }

  PostAuditLogs(codeVal: AuditLogs): Observable<any> {
    return this.apiService.post(this.config.PostAuditLogs, codeVal)
      .pipe(map(
        data => data
      ));
  }

  GetAuditLogList(num: number): Observable<CustomAuditLogs[]> {
    return this.apiService.get(this.config.GetAuditLogs + "/" +num)
      .pipe(map(
        data => data
      ));
  }


  GetAuditLogSettings(): Observable<AuditLogsPaginationSettings[]> {

    return this.apiService.get(this.config.AuditLogsSettings)
      .pipe(map(
        data => data
      ));
  }

  GetUserAuditLogList(currentpage: number, recordsnext: number): Observable<CustomAuditLogs[]> {
    var customerid = sessionStorage.getItem("Scustomerid");
    var userid = sessionStorage.getItem("Suserid");
    return this.apiService.get(this.config.UserAuditLogs + "/" + customerid + "/" + userid + "/" + currentpage + "/" + recordsnext)
      .pipe(map(
        data => data
      ));
  }


  GetAzureFeature() {
    if ((Boolean((Number(sessionStorage.getItem("SAzureFeature")) & Number(sessionStorage.getItem(this.actionVariable)))))) {
      this.showazureActionButton = true;
    }
    else {
      this.showazureActionButton = false;
    }

    if ((Boolean((Number(sessionStorage.getItem("SAzureFeature")) & Number(sessionStorage.getItem(this.jiraVariable)))))) {
      this.showazureJiraButton = true;
    }
    else {
      this.showazureJiraButton = false;
    }


  }

  GetGCPFeature(): string {

    if (Boolean((Number(sessionStorage.getItem("SGcpFeature"))) & (Number(sessionStorage.getItem(this.actionVariable))))) {
      this.showgcpActionButton = true;
    }
    else {
      this.showgcpActionButton = false;
    }

    if ((Boolean((Number(sessionStorage.getItem("SGcpFeature")) & Number(sessionStorage.getItem(this.jiraVariable)))))) {
      this.showgcpJiraButton = true;
    }
    else {
      this.showgcpJiraButton = false;
    }
    return "";
  }

  GetAWSFeature() {

    if ((Boolean((Number(sessionStorage.getItem("SAwsFeature")) & Number(sessionStorage.getItem(this.actionVariable)))))) {
      this.showawsActionButton = true;
    }
    else {
      this.showawsActionButton = false;
    }

    if ((Boolean((Number(sessionStorage.getItem("SAwsFeature")) & Number(sessionStorage.getItem(this.jiraVariable)))))) {
      this.showawsJiraButton = true;
    }
    else {
      this.showawsJiraButton = false;
    }
  }

  GetM365Feature() {

    if ((Boolean((Number(sessionStorage.getItem("SM365Feature")) & Number(sessionStorage.getItem(this.actionVariable)))))) {
      this.showm365ActionButton = true;
    }
    else {
      this.showm365ActionButton = false;
    }

    if ((Boolean((Number(sessionStorage.getItem("SM365Feature")) & Number(sessionStorage.getItem(this.jiraVariable)))))) {
      this.showm365JiraButton = true;
    }
    else {
      this.showm365JiraButton = false;
    }
  }

  CheckPremiumFeature() {
    //if (sessionStorage.getItem("SAzureFeature").toLowerCase().includes("3909FA23-2B6F-4C8D-9DCD-5961E27663B6") || sessionStorage.getItem("SAwsFeature").toLowerCase().includes("3909FA23-2B6F-4C8D-9DCD-5961E27663B6")
    //  || sessionStorage.getItem("SGcpFeature").toLowerCase().includes("3909FA23-2B6F-4C8D-9DCD-5961E27663B6")) {
    //  this.showContinuous = true;
    //}
    //else {
    //  this.showContinuous = false;
    //}

    if ((Boolean((Number(sessionStorage.getItem("SAzureFeature")) & Number(sessionStorage.getItem(this.autoscanVariable)))))
      || (Boolean((Number(sessionStorage.getItem("SAwsFeature")) & Number(sessionStorage.getItem(this.autoscanVariable)))))
      || (Boolean((Number(sessionStorage.getItem("SGcpFeature")) & Number(sessionStorage.getItem(this.autoscanVariable)))))
      || (Boolean((Number(sessionStorage.getItem("SM365Feature")) & Number(sessionStorage.getItem(this.autoscanVariable)))))) {
      this.showContinuous = true;
    }
    else {
      this.showContinuous = false;
    }

    
  }

  GetUserAuditLogSettings(pagesize: number): Observable<AuditLogsPaginationSettings[]> {
    var customerid = sessionStorage.getItem("Scustomerid");
    var userid = sessionStorage.getItem("Suserid");
    return this.apiService.get(this.config.UserAuditLogsSettings + "/" + customerid + "/" + userid + "/" + pagesize + "/" +sessionStorage.getItem("sprojectID"))
      .pipe(map(
        data => data
      ));
  }
}
