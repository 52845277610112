
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONFIG, AppConfig } from '../../config/app.config';
import { SubscriptionCompliance, SubscriptionScan, ScanDetailsForControls, SubscriptionStatus, SubscriptionDetails } from '../../model/subscriptionCompliance.model'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { strict } from 'assert';
import { SyncAsync } from '@angular/compiler/src/util';
import { Router } from '@angular/router';
import { ParamsComplianceQumuliScore, ComplianceQumuliScore, DashboardDataCls } from '../../model/reports';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { Providermaster } from '../../model/providermaster';
import { Compliancestandard, ScanAction, SubscriptionScanAction } from '../../model/compliancestandard';
import { AppConfigService } from '../../config/AppConfig.config';
import { LogService } from '../../service/childservice/log.service';

@Injectable({
  providedIn: 'root'
})
export class AWSScannerService {

  private config: AppConfig;
  public PageName: string = "AWSScannerService";

  constructor(private logService: LogService, private router: Router, private apiService: ApiService, private appConfigService: AppConfigService, 
    private http: HttpClient) { this.config = appConfigService.appConfig; }//, @Inject(APP_CONFIG) private config: AppConfig


  AWSScanner(scriptionDetails: SubscriptionDetails[]) {

    let complianceId = sessionStorage.getItem("ComplianceId");
    try {
      for (let subscriptionDetails of scriptionDetails) {
        var oSubscriptionCompliance = new SubscriptionCompliance;
        oSubscriptionCompliance.complianceId = Number(complianceId);
        oSubscriptionCompliance.subscriptionId = subscriptionDetails.subscriptionId;
        oSubscriptionCompliance.lockDown = false;
        oSubscriptionCompliance.lockDownReason = "";

        this.SaveSubscriptionCompliance(oSubscriptionCompliance).subscribe(result => {

          let objSubscriptionScan = new SubscriptionScan;

          objSubscriptionScan.subscriptionId = subscriptionDetails.subscriptionId;
          objSubscriptionScan.subComplianceId = result.subscriptionComplianceId;
          objSubscriptionScan.scanningStatus = "Started";
          objSubscriptionScan.scanningType = "Manual";
          objSubscriptionScan.noControlsFailed = 0;
          objSubscriptionScan.noControlsPassed = 0;
          objSubscriptionScan.noControlsScanned = 0;
          objSubscriptionScan.scanningComponent = "";
          objSubscriptionScan.scanStart = new Date();
          objSubscriptionScan.scanningDate = new Date();
          objSubscriptionScan.initializedby = sessionStorage.getItem("Suserid");
          let conf = this.config;
          this.SaveSubscriptionScan(objSubscriptionScan).subscribe(SubscriptionScanresult => {

            sessionStorage.setItem("scanningId", SubscriptionScanresult.scanningId);
            let id = SubscriptionScanresult.scanningId;

            this.CallAWSFunction(SubscriptionScanresult.scanningId, Number(complianceId), subscriptionDetails.subscriptionId).subscribe(awsfun => {

              this.CalculateControlScorePerSubscription(conf.calculateControlScorePerSubscription, id);


            });

            if (scriptionDetails.length == 1)
              this.router.navigate(['/compliance/provider/' + sessionStorage.getItem("SelectedComplianceIdType") + '/subscriptionscan/awsscanreport']);
              //this.router.navigate(['/compliance/aprovidermaster/subscriptionscan/AwsScanreport']);

          });

        });

      }

      if (scriptionDetails.length != 1) {
        this.router.navigate(['/compliance/subscriptionscanning']);
      }
      //sessionStorage.setItem("scanningIds", JSON.stringify(scanningIds));
    } catch (error) {
      this.logService.LogError(error, "", this.PageName);

    }
  }

  SaveSubscriptionScan(subscriptionScan: SubscriptionScan): Observable<SubscriptionScan> {

    try {
      return this.apiService.post(this.config.saveSubscriptionScan, subscriptionScan)
        .pipe(map(
          data => data
        ));
    } catch (error) {
      this.logService.LogError(error, "", this.PageName);

    }
  }

  SaveSubscriptionCompliance(compliance: SubscriptionCompliance): Observable<SubscriptionCompliance> {
    //return this.http.post<SubscriptionCompliance>(url, Compliance).toPromise();
    try {
      return this.apiService.post(this.config.SaveSubscriptionCompliance, compliance)
        .pipe(map(
          data => data
        ));
    } catch (error) {
      this.logService.LogError(error, "", this.PageName);
    }
  }





  CallAWSFunction(ScanningId: string, ComplianceId: number, SubscriptionId: number) {
    try {
      return this.apiService.get(this.config.AWSfunction + "&ScanningId=" + ScanningId + " &ComplianceId=" + ComplianceId + " &QumuliSubscriptionId=" + SubscriptionId + "&QumuliToken=" + sessionStorage.getItem("Stoken"))
        .pipe(map((data => data)));
    } catch (error) {
      this.logService.LogError(error, "", this.PageName);

    //update qumuli score for each control post successful scan

    }
  }

  async CalculateControlScorePerSubscription(url: string, id: string) {
    const headersVal = new HttpHeaders({ 'Authorization': 'Bearer ' + sessionStorage.getItem("Stoken") });
    return this.http.post<any>(url + '?id=' + id, { id: id }, { headers: headersVal }).toPromise();
  }

}
