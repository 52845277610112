import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-support-layout',
  templateUrl: './support-layout.component.html',
  styleUrls: ['./support-layout.component.scss']
})
export class SupportLayoutComponent implements OnInit {
  currentYear: any;
  ngOnInit() {
    this.currentYear = new Date();
  }
}
