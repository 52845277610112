import { Injectable, Inject } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppConfigService, AppConfig } from '../../config/AppConfig.config';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  private config: AppConfig

  constructor(private apiService: ApiService, private appConfigService: AppConfigService) {
    this.config = appConfigService.appConfig;
  }


  LogError(Message: string, Exception: string, Logger: string) {

    var log = new Log();
    log.Thread = "1";
    log.Exception = Exception;
    log.Level = "ERROR";
    log.Logger = Logger;
    log.Message = Message;

    this.apiService.post(this.config.LogCreation, log).subscribe(data => {
      //console.log(this.config.LogCreation);
      //console.log(data);
    });

    
  }


}

export class Log {
  Id: number;
  Date: Date;
  Thread: string;
  Level: string;
  Logger: string;
  Message: string;
  Exception: string;
}
       
        
