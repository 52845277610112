import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

export class RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  role: string;
}

export class RouteSubInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const ROUTES: RouteInfo[] = [
  { path: '/compliance/dashboard', title: 'Dashboard', icon: 'fa-home', class: '1', role: 'admin' },
  { path: '/compliance/providermaster', title: 'Scan', icon: 'fa-radar', class: '1', role: 'admin' },
  { path: '/compliance/users', title: 'Users', icon: 'fad fa-users', class: 'nav-item', role: 'admin' },
  //{ path: '/compliance/customers', title: 'Customers', icon: 'far fa-house-user', class: 'nav-item', role: 'admin' },
  //{ path: '/compliance/customcompliance', title: 'Compliance', icon: 'fad fa-tasks-alt', class: 'nav-item', role: 'admin' },
  { path: '/compliance/reports', title: 'Reports', icon: 'fa-tachometer-alt', class: 'nav-item', role: 'admin' },

];


export const SecOps: RouteInfo[] = [
  { path: '/compliance/dashboard', title: 'Dashboard', icon: 'fa-home', class: '1', role: 'admin' },
  { path: '/compliance/providermaster', title: 'Scan', icon: 'fa-radar', class: '1', role: 'admin' },
  //{ path: '/compliance/customers', title: 'Customers', icon: 'far fa-house-user', class: 'nav-item', role: 'admin' },
  //{ path: '/compliance/customcompliance', title: 'Compliance', icon: 'fad fa-tasks-alt', class: 'nav-item', role: 'admin' },
  { path: '/compliance/reports', title: 'Reports', icon: 'fa-tachometer-alt', class: 'nav-item', role: 'admin' },

];


export const ReportOperator	: RouteInfo[] = [
  { path: '/compliance/dashboard', title: 'Dashboard', icon: 'fa-home', class: '1', role: 'admin' },
  { path: '/compliance/providermaster', title: 'Scan', icon: 'fa-radar', class: '1', role: 'admin' },
  //{ path: '/compliance/customers', title: 'Customers', icon: 'far fa-house-user', class: 'nav-item', role: 'admin' },
  //{ path: '/compliance/customcompliance', title: 'Compliance', icon: 'fad fa-tasks-alt', class: 'nav-item', role: 'admin' },
  { path: '/compliance/reports', title: 'Reports', icon: 'fa-tachometer-alt', class: 'nav-item', role: 'admin' },

];


@Component({
  //moduleId: module.id,
  selector: 'Supportsidebar-cmp',
  templateUrl: 'supportsidebar.component.html',
})

export class SupportSidebarComponent implements OnInit {
  constructor( private http: HttpClient, private router: Router) {

  }

  ngOnInit() {
  }
}
