import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';



@Injectable()
export class AppConfigService {

  private appConfigdata: AppConfigdata;
  public appConfig: AppConfig

  constructor(private http: HttpClient) {
    this.appConfig = new AppConfig();
    this.appConfigdata = new AppConfigdata();

  }

  public Startup(): AppConfigdata {
    return this.appConfigdata;
  }

  load() {

    let BaseURL = document.getElementsByTagName('base')[0].href;
    return new Promise((resolve, reject) => {
      this.http
        .get(BaseURL + "api/Startup/GetStartupValues")
        .subscribe(response => {
          this.appConfigdata.BaseURL = response["baseURL"];
          this.appConfigdata.Azurefunction = response["azurefunction"];
          this.appConfigdata.Azurefeature = response["azurefeature"];
          this.appConfigdata.AzureActionfunction = response["azureActionfunction"];
          this.appConfigdata.AWSfunction = response["awSfunction"];
          this.appConfigdata.AWSRemediatefunction = response["awsRemediatefunction"];          
          this.appConfigdata.AWSActionfunction = response["awsActionfunction"];
          this.appConfigdata.M365function = response["m365function"];
          this.appConfigdata.GCPfunction = response["gcPfunction"];
          this.appConfigdata.GCPActionfunction = response["gcpActionfunction"];
          this.appConfigdata.QumuliAPIURL = response["qumuliAPIURL"];
          this.appConfigdata.UXBaseURL = BaseURL;
          this.appConfigdata.AppClientId = response["appClientId"];
          this.appConfigdata.SchedulerUIURL = response["schedulerUIURL"];
          this.appConfigdata.Recaptcha3_siteKey = response["recaptcha3_siteKey"];
          this.appConfigdata.JiraURL = response["jiraURL"];
          this.appConfigdata.JiraClientId = response["jiraClientId"];
          this.appConfigdata.JiraClientSecret = response["jiraClientSecret"];
          this.SetDefault();
          resolve(true);
        })
    })
  }




  public SetDefault() {
    this.appConfig.AGGridDefaultHeight = 110;
    this.appConfig.AGGridRowHeight = 42.5;
    this.appConfig.JiraClientId = this.appConfigdata.JiraClientId;
    this.appConfig.JiraClientSecret = this.appConfigdata.JiraClientSecret;
    this.appConfig.AGGridTheme = "ag-theme-alpine table table-striped";
    this.appConfig.Recaptcha3_siteKey = this.appConfigdata.Recaptcha3_siteKey;
    this.appConfig.SchedulerUIURL = this.appConfigdata.SchedulerUIURL;
    this.appConfig.JiraURL = this.appConfigdata.JiraURL;
    this.appConfig.userlogin = this.appConfigdata.BaseURL + 'Mid/LoginUser';
    this.appConfig.OtpMid = this.appConfigdata.BaseURL + 'Mid/SendUserOtp/';
    this.appConfig.Azurefunction = this.appConfigdata.Azurefunction;
    this.appConfig.Azurefeature = this.appConfigdata.Azurefeature;
    this.appConfig.AWSfunction = this.appConfigdata.AWSfunction;
    this.appConfig.AWSRemediatefunction = this.appConfigdata.AWSRemediatefunction;
    this.appConfig.M365function = this.appConfigdata.M365function;
    this.appConfig.GCPfunction = this.appConfigdata.GCPfunction;
    this.appConfig.AWSActionfunction = this.appConfigdata.AWSActionfunction;
    this.appConfig.GCPActionfunction = this.appConfigdata.GCPActionfunction;
    this.appConfig.resetpassword = this.appConfigdata.BaseURL + 'Mid/ForgetpasswordPost/';
    this.appConfig.SubscriptionScanHistory = this.appConfigdata.QumuliAPIURL + 'Subscriptions/SubscriptionScanHistory';
    this.appConfig.ResetPwdbyEmail = this.appConfigdata.BaseURL + 'Mid/ResetpasswordPost';
    this.appConfig.PostAuthcode = this.appConfigdata.BaseURL + 'Mid/PostAuthcode';
    this.appConfig.SecondStepGraph = this.appConfigdata.BaseURL + 'admin/SecondStepGraph/';
    this.appConfig.EmailConfirmation = this.appConfigdata.BaseURL + 'Mid/UpdateCustomerEmailconformations/';
    this.appConfig.dashbaordtoppanel = this.appConfigdata.QumuliAPIURL + 'Reports/GetDashboarddata';
    this.appConfig.dashboardDataNumberOfScan = this.appConfigdata.QumuliAPIURL + 'Reports/GetDashboardNumberofScan';
    this.appConfig.GetProjectUser = this.appConfigdata.QumuliAPIURL + 'ProjectMaster/GetProjectUsers/';
    this.appConfig.logout = this.appConfigdata.BaseURL + 'Mid/LogoutUser';
    this.appConfig.userlogin = this.appConfigdata.BaseURL + 'Mid/LoginUser';
    this.appConfig.PutSaveSubscription = this.appConfigdata.QumuliAPIURL + 'Subscriptions/PutSubscription/';
    this.appConfig.DeleteSubscriptions = this.appConfigdata.QumuliAPIURL + 'Subscriptions/SubscriptionDelete/';
    this.appConfig.saveSubscriptions = this.appConfigdata.QumuliAPIURL + 'Subscriptions/PostSubscription';
    this.appConfig.PostSaveSubscription = this.appConfigdata.QumuliAPIURL + 'Subscriptions/PostSaveSubscription';
    this.appConfig.getCustomerIdByAspnetUserid = this.appConfigdata.QumuliAPIURL + 'UserLogins/GetCustomerIdByAspnetUserid/';
    this.appConfig.saveSubscriptionScan = this.appConfigdata.QumuliAPIURL + 'SubscriptionScans/PostSubscriptionScan';
    this.appConfig.calculateControlScorePerSubscription = this.appConfigdata.QumuliAPIURL + 'SubscriptionScans/CalculateControlScorePerSubscription';
    this.appConfig.saveSubscriptionCompliance = 'SubscriptionScans/PostSubscriptionScan';
    this.appConfig.getSubscriptionControlGroupDetails = 'Subscriptions/GetSubscriptionControlGroupDetails';
    this.appConfig.reports = this.appConfigdata.QumuliAPIURL + 'Reports/GetOverallQumuliscore';
    this.appConfig.DashboardCompliance = this.appConfigdata.QumuliAPIURL + 'Reports/GetDashboardNumberofCompliance';
    this.appConfig.AppClientId = this.appConfigdata.AppClientId;//'93f4a091-8ad3-4ef1-a097-441009e02d0e';
    //AppSecerateId= 'RWL]l]M+[^Fn/4_j)_6{54[(5Nxe';
    this.appConfig.dashboardAssetLevelCompliance = this.appConfigdata.QumuliAPIURL + 'Reports/GetDashboardAssetLevelCompliance';
    this.appConfig.dashboardMonthWisePassVsFail = this.appConfigdata.QumuliAPIURL + 'Reports/GetDashboardMonthWisePassVsFail';
    this.appConfig.ScanHistory = this.appConfigdata.QumuliAPIURL + 'Subscriptions/SubscriptionScanHistory/';
    this.appConfig.Industries = this.appConfigdata.QumuliAPIURL + 'Industries';
    this.appConfig.LicenseFeatureList = this.appConfigdata.QumuliAPIURL + 'CustomerLicense/GetLicenseFeatureList'
    this.appConfig.Customer = this.appConfigdata.QumuliAPIURL + 'Customers/';
    this.appConfig.dashboardComplianceWised = this.appConfigdata.QumuliAPIURL + 'Reports/GetComplianceBasedData';
    this.appConfig.ForgetPassword = this.appConfigdata.BaseURL + 'Mid/Forgetpassword';
    this.appConfig.GetLicenses = this.appConfigdata.QumuliAPIURL + 'Licenses';
    this.appConfig.GetIndustries = this.appConfigdata.QumuliAPIURL + 'Industries';
    this.appConfig.PostCustomer = this.appConfigdata.BaseURL + 'Mid/PostCustomeruser';
    this.appConfig.PostManagement = this.appConfigdata.BaseURL + 'AzureAD/PostManagement';
    this.appConfig.Post = this.appConfigdata.BaseURL + 'AzureAD/Post';
    this.appConfig.ComplianceStandards = this.appConfigdata.QumuliAPIURL + 'ComplianceStandards/';
    this.appConfig.subscriptionscanning = this.appConfigdata.QumuliAPIURL + 'Subscriptions/SubscriptionScanning/';
    this.appConfig.reportmonthly = this.appConfigdata.QumuliAPIURL + 'Reports/GetScanningListByComplianeMonthWise';
    this.appConfig.subscriptionscanstatus = this.appConfigdata.QumuliAPIURL + 'SubscriptionScans/GetSubscriptionScans/';
    this.appConfig.controlgroupdetails = this.appConfigdata.QumuliAPIURL + 'Subscriptions/GetSubscriptionControlGroupDetails/';
    this.appConfig.GetSubscriptionbyUserid = this.appConfigdata.QumuliAPIURL + 'Subscriptions/GetSubscriptionsByUserId/';
    this.appConfig.GetSubscriptionScanReport = this.appConfigdata.QumuliAPIURL + 'SubscriptionScans/GetSubscriptionScanReport/';
    this.appConfig.GetTokenApi = this.appConfigdata.QumuliAPIURL + 'CustomerTokenDetails/GetCustomerTokenDetailsByCustomerId/';
    this.appConfig.GetCustomerLicFeatureApi = this.appConfigdata.QumuliAPIURL + 'CustomerTokenDetails/GetCustomerLicenseFeature/';
    this.appConfig.GetFeatureMasterApi = this.appConfigdata.QumuliAPIURL + 'CustomerTokenDetails/GetFeatureMasterList';
    this.appConfig.GetSubscriptionbyCustomerId = this.appConfigdata.QumuliAPIURL + 'Subscriptions/GetSubscriptionsByCustomerId/';
    this.appConfig.GetSubscriptionAWSbyCustomerId = this.appConfigdata.QumuliAPIURL + 'Subscriptions/GetSubscriptionsByAWSCustomerId/';
    this.appConfig.GetSubscriptionGCPbyCustomerId = this.appConfigdata.QumuliAPIURL + 'Subscriptions/GetSubscriptionsByGCPCustomerId/';
    this.appConfig.GetSubscriptionM365byCustomerId = this.appConfigdata.QumuliAPIURL + 'Subscriptions/GetSubscriptionsByM365CustomerId/';
    this.appConfig.GetSubScanbySubId = this.appConfigdata.QumuliAPIURL + 'SubscriptionScans/GetSubscriptionScanBySubscriptionId/';
    this.appConfig.PostScanAction = this.appConfigdata.QumuliAPIURL + 'ScanActions/PostScanAction';
    this.appConfig.GetScanAction = this.appConfigdata.QumuliAPIURL + 'ScanActions/GetScanAction/';
    this.appConfig.GetScanActions = this.appConfigdata.QumuliAPIURL + 'ScanActions/GetScanActions/';
    this.appConfig.AzureActionfunction = this.appConfigdata.AzureActionfunction;
    this.appConfig.PostSubscriptionScanAction = this.appConfigdata.QumuliAPIURL + 'ScanActions/PostSubscriptionScanAction';
    this.appConfig.SaveSubscriptionCompliance = this.appConfigdata.QumuliAPIURL + 'SubscriptionCompliances';
    this.appConfig.ProviderMasters = this.appConfigdata.QumuliAPIURL + 'ProviderMasters';
    this.appConfig.ResendOtpApi = this.appConfigdata.BaseURL + 'Mid/ResendOtp';
    this.appConfig.ValidateEmail = this.appConfigdata.BaseURL + 'Mid/VerifyEmailId';
    this.appConfig.ValidateName = this.appConfigdata.BaseURL + 'Mid/VerifyUserName';
    this.appConfig.UXBaseURL = this.appConfigdata.UXBaseURL;
    this.appConfig.StorageAccounts = this.appConfigdata.QumuliAPIURL + 'SubscriptionScans/GetStorageAccountsName/';
    this.appConfig.AspNetRoles = this.appConfigdata.QumuliAPIURL + 'AspNetRoles/GetAspNetRoles/';
    this.appConfig.AspNetUserRoles = this.appConfigdata.QumuliAPIURL + 'AspNetRoles/GetAspNetRoles/';
    this.appConfig.GetUsersByCustomerId = this.appConfigdata.QumuliAPIURL + 'SubscriptionScans/GetUsersByCustomerId/';
    this.appConfig.PostCustomeruserdetails = this.appConfigdata.BaseURL + 'Mid/PostCustomeruserdetails';
    this.appConfig.PutCustomeruserdetails = this.appConfigdata.BaseURL + 'Mid/PutCustomeruserdetails/';
    this.appConfig.DeleteUserId = this.appConfigdata.QumuliAPIURL + 'SubscriptionScans/DeleteUsers/';
    this.appConfig.MyComplianceControl = this.appConfigdata.QumuliAPIURL + 'MyCompliances/GetMyComplianceControl/';
    this.appConfig.GetProviderMaster = this.appConfigdata.QumuliAPIURL + 'ProviderMasters';
    this.appConfig.GetRegion = this.appConfigdata.QumuliAPIURL + 'CustomTag/GetRegion';
    this.appConfig.SaveCompliancestandard = this.appConfigdata.QumuliAPIURL + 'ComplianceStandards';
    this.appConfig.UpdateCompliancestandard = this.appConfigdata.QumuliAPIURL + 'ComplianceStandards/';
    this.appConfig.DeleteCompliancestandard = this.appConfigdata.QumuliAPIURL + 'ComplianceStandards/';
    this.appConfig.SaveMyCompliance = this.appConfigdata.QumuliAPIURL + 'MyCompliances/PostMyCompliance';
    this.appConfig.DeleteMyCompliance = this.appConfigdata.QumuliAPIURL + 'MyCompliances/DeleteMyCompliance/';
    this.appConfig.GetComplianceControls = this.appConfigdata.QumuliAPIURL + 'ComplianceControls/GetStandardsControl/';
    this.appConfig.SaveComplianceControls = this.appConfigdata.QumuliAPIURL + 'MyComplianceMasters/PostMyComplianceMaster';
    this.appConfig.GetMyComplianceMaster = this.appConfigdata.QumuliAPIURL + 'MyCompliances/GetMyComplianceMaster/';
    this.appConfig.GetSelectedCompliance = this.appConfigdata.QumuliAPIURL + 'ComplianceStandards/GetCompliance/';
    this.appConfig.DeleteMyComplianceMaster = this.appConfigdata.QumuliAPIURL + 'MyComplianceMasters/DeleteMyComplianceMaster/';
    this.appConfig.CustomDeleteMyComplianceMaster = this.appConfigdata.QumuliAPIURL + 'MyComplianceMasters/CustomDeleteMyComplianceMaster/';
    this.appConfig.Providercompliance = this.appConfigdata.QumuliAPIURL + 'MyCompliances/GetProviderComplianceControl/';
    this.appConfig.GetCustomerUrl = this.appConfigdata.QumuliAPIURL + 'Customers';
    this.appConfig.GetCustomerListUrl = this.appConfigdata.QumuliAPIURL + 'UserLogins/GetCustomerList';
    this.appConfig.Updateuser = this.appConfigdata.QumuliAPIURL + 'UserLogins/UpdateUsersByCustomerId/';
    this.appConfig.PostGCPConnection = this.appConfigdata.BaseURL + 'GCP/PostGoogleLoginPart';
    this.appConfig.SendCustEmail = this.appConfigdata.BaseURL + 'Mid/SendCustomerStatusMail';
    this.appConfig.GetUserRole = this.appConfigdata.BaseURL + 'Mid/GetAspNetUsersbyEmail';
    this.appConfig.Customers = this.appConfigdata.UXBaseURL + 'bo/customers/';// BaseURL + 'superadmin/customers',
    this.appConfig.Authorization = this.appConfigdata.UXBaseURL + 'bo/Authorization';
    this.appConfig.LogCreation = this.appConfigdata.QumuliAPIURL + 'Logs/PostLogs';
    this.appConfig.JiraIssueCreation = this.appConfigdata.BaseURL + 'Integration/CreateJiraTask';
    this.appConfig.PostAuditLogs = this.appConfigdata.QumuliAPIURL + 'AuditLogs';
    this.appConfig.GetAuditLogs = this.appConfigdata.QumuliAPIURL + 'AuditLogs';
    this.appConfig.ApiUrl = this.appConfigdata.QumuliAPIURL;
    this.appConfig.SaveQuartzTrigger = this.appConfigdata.QumuliAPIURL + 'QuartzTrigger/PutQuartzTriggerJobDataMap';
    this.appConfig.GetQuartzTrigger = this.appConfigdata.QumuliAPIURL + 'Qrtz_triggers/GetScheduler/';
    this.appConfig.QuartzSubscriptions = this.appConfigdata.QumuliAPIURL + 'Subscriptions/GetQuartzSubscriptionsByCustomerId/';
    this.appConfig.QuartzSubscriptionsDeletion = this.appConfigdata.QumuliAPIURL + 'Qrtz_triggers/DeleteQrtzTriggers/';
    this.appConfig.QuartzSubscriptionsUpdate = this.appConfigdata.QumuliAPIURL + 'Qrtz_triggers/UpdateJobStatus';
    this.appConfig.GetCustomerLicense = this.appConfigdata.QumuliAPIURL + 'Qrtz_triggers/GetCustomerLicenses/';
    this.appConfig.GetCustomerLicenseContinuous = this.appConfigdata.QumuliAPIURL + 'Qrtz_triggers/GetCustomerLicensesInContinuous/';
    this.appConfig.GetTimeZoneList = this.appConfigdata.QumuliAPIURL + 'TimeZone/GetTimeZone';
    this.appConfig.Save_CustomerSettings = this.appConfigdata.QumuliAPIURL + 'CustomerSettings/PostCustomerSettings';
    this.appConfig.Get_CustomerSettings = this.appConfigdata.QumuliAPIURL + 'CustomerSettings/GetCustomerSettings/';
    this.appConfig.GetTimezone_CustomerSettings = this.appConfigdata.QumuliAPIURL + 'CustomerSettings/GetCustomerTimezoneSettings/';
    this.appConfig.Update_CustomerSettings = this.appConfigdata.QumuliAPIURL + 'CustomerSettings/PutCustomerSettings/';
    this.appConfig.GetSubscriptionExists = this.appConfigdata.QumuliAPIURL + 'Qrtz_triggers/GetSubscriptionExists/';
    this.appConfig.Midpdf = this.appConfigdata.BaseURL + 'Aspose/GetSelectPdf';
    this.appConfig.PostUpdateCustomerLicense = this.appConfigdata.QumuliAPIURL + 'CustomerLicense/PostUpdateCustomerLicense';
    this.appConfig.PostGetCustomerLicense = this.appConfigdata.QumuliAPIURL + 'CustomerLicense/PostGetCustomerLicense/';
    this.appConfig.MarketPlace = this.appConfigdata.BaseURL + 'MarketPlace/SaveMarketPlaceUser';
    this.appConfig.AuditLogsSettings = this.appConfigdata.QumuliAPIURL + 'Logs/AuditLogsPagination';
    this.appConfig.UserAuditLogs = this.appConfigdata.QumuliAPIURL + 'UserLogs/GetAuditLog';
    this.appConfig.UserAuditLogsSettings = this.appConfigdata.QumuliAPIURL + 'UserLogs/AuditLogsPagination';
    this.appConfig.DashboardAnalysisAssets = this.appConfigdata.QumuliAPIURL + 'Dashboard/GetGetAnalysisAssets';
    this.appConfig.DashboardAnalysisAssetsdrilldown = this.appConfigdata.QumuliAPIURL + 'Dashboard/GetGetAnalysisAssetsDrillDown';
    this.appConfig.GetAnalysisAssets_Drilldowninfo = this.appConfigdata.QumuliAPIURL + 'Dashboard/AnalysisAssets_Drilldowninfo';
    this.appConfig.MidSupportSendMail = this.appConfigdata.BaseURL + 'Support/SupportMail';
    this.appConfig.AdminSubscription = this.appConfigdata.QumuliAPIURL + 'Dashboard/GetAdminSubscriptionDetails';
    this.appConfig.AdminProvider = this.appConfigdata.QumuliAPIURL + 'Dashboard/GetAdminProviderDetails';
    this.appConfig.GetCustomerLicensesForDisplay = this.appConfigdata.QumuliAPIURL + 'Qrtz_triggers/GetCustomerLicensesForDisplay/';
    this.appConfig.M365PostSaveSubscription = this.appConfigdata.QumuliAPIURL + 'Subscriptions/PostM365Subscription';
    this.appConfig.DashboardAnalysisPlatform = this.appConfigdata.QumuliAPIURL + 'Dashboard/GetGetAnalysisPlatform';
    this.appConfig.AssetsInformation = this.appConfigdata.QumuliAPIURL + 'Assets/GetAssetsInformation';
    this.appConfig.AssetsSelectedInformation = this.appConfigdata.QumuliAPIURL + 'Assets/GetSelectedAssetsInformation';
    this.appConfig.GetAssetsDetails = this.appConfigdata.QumuliAPIURL + 'Assets/GetSelectedAssetsDetails';
    this.appConfig.AssetsSearch = this.appConfigdata.QumuliAPIURL + 'Assets/GetGetSearch_Result';
    this.appConfig.GetScandifference = this.appConfigdata.QumuliAPIURL + 'Assets/GetScandifference';
    this.appConfig.GetAssetsdifference = this.appConfigdata.QumuliAPIURL + 'Assets/GetAssetsdifference';
    this.appConfig.CronExpression = "0 0 6,18 1/1 * ? *";
    this.appConfig.EmailAlert = this.appConfigdata.QumuliAPIURL + 'Email/ScanAlertMail';
    this.appConfig.GetCustomTag = this.appConfigdata.QumuliAPIURL + 'CustomTag/GetCustomTag';
    this.appConfig.SaveCustomTag = this.appConfigdata.QumuliAPIURL + 'CustomTag/PostCustomTag';
    this.appConfig.DeleteCustomTag = this.appConfigdata.QumuliAPIURL + 'CustomTag/DeleteCustomTag/';
    this.appConfig.GetDashboardCustomTag = this.appConfigdata.QumuliAPIURL + 'CustomTag/GetCustomTags/';
    this.appConfig.GetDashboardCustomTagInformation = this.appConfigdata.QumuliAPIURL + 'CustomTag/GetDashBoardCustomTaginformation';
    this.appConfig.GetPolicyVoilationDetails = this.appConfigdata.QumuliAPIURL + 'Dashboard/GetPolicyVoilationDetails';
    this.appConfig.CloudAssets = this.appConfigdata.QumuliAPIURL + 'Assets/GetCloudAssets';
    this.appConfig.SupportMail = this.appConfigdata.QumuliAPIURL + 'Email/SupportMail';
    this.appConfig.FeedbackMail = this.appConfigdata.QumuliAPIURL + 'Email/FeedbackMail';
    this.appConfig.NetworkInventoryCountConfig = this.appConfigdata.QumuliAPIURL + 'Dashboard/GetNetworkInventoryCount';
    this.appConfig.OneClickAudit = this.appConfigdata.QumuliAPIURL + 'OneClickAudit/PostOneClickAudit';
    this.appConfig.NetworkInventoryConfig = this.appConfigdata.QumuliAPIURL + 'Dashboard/GetNetworkInventory';
    this.appConfig.NetworkInventoryUDRConfig = this.appConfigdata.QumuliAPIURL + 'Dashboard/GetNetworkInventoryUDR';
    this.appConfig.GetDashboard_cloud = this.appConfigdata.QumuliAPIURL + 'Reports/GetDashboardDashboard_cloud';
    this.appConfig.GetDashboard_Cloud_Compliance = this.appConfigdata.QumuliAPIURL + 'Reports/GetDashboardDashboard_Cloud_Compliance';
    this.appConfig.GetDashboard_Compliance_Subscription = this.appConfigdata.QumuliAPIURL + 'Reports/GetDashboardDashboard_Compliance_Subscription';
    this.appConfig.JiraAccount = this.appConfigdata.BaseURL + "Integration/Post";
    this.appConfig.NetworkInventoryConfig = this.appConfigdata.QumuliAPIURL + 'Dashboard/GetNetworkInventory';
    this.appConfig.NetworkInventoryUDRConfig = this.appConfigdata.QumuliAPIURL + 'Dashboard/GetNetworkInventoryUDR';
    this.appConfig.JiraGetProject = this.appConfigdata.BaseURL + "Integration/GetJiraProject";
    this.appConfig.JiraUpdatetTicket = this.appConfigdata.QumuliAPIURL + "SubscriptionScans/UpdateJiraTicket";
    this.appConfig.JiraGetProject = this.appConfigdata.BaseURL + "Integration/GetJiraProject";
    this.appConfig.JiraStatusTicket = this.appConfigdata.BaseURL + "Integration/StatusJiraTask";
    this.appConfig.JiraReturnUrl = this.appConfigdata.BaseURL + "compliance/Jiralogin";
    this.appConfig.GetIpaddress = this.appConfigdata.BaseURL + "Startup/GetIPAddressValues";
    this.appConfig.DefaultTag = this.appConfigdata.QumuliAPIURL + 'CustomTag/GetDefaultTag';
    this.appConfig.VMInstallPatches = this.appConfigdata.QumuliAPIURL + 'Subscriptions/GetVMInstalledPatchesByScanId/';
    this.appConfig.ListAzureAuth = this.appConfigdata.QumuliAPIURL + 'MyCompliances/GetAzureAuthApps/'; //Deepa : 804
    this.appConfig.GetVMPatches = this.appConfigdata.QumuliAPIURL + 'Assets/GetPatchesDetails'
    this.appConfig.AzureAuthPostSaveSubscription = this.appConfigdata.QumuliAPIURL + 'Subscriptions/PostAzureAuth'; //Deepa : 804
    this.appConfig.AzureAuthPostUpdateSubscription = this.appConfigdata.QumuliAPIURL + 'Subscriptions/UpdateAzureAuth'; //Deepa : 804
    this.appConfig.GetSubscriptionbyCustomerIdwithAuthId = this.appConfigdata.QumuliAPIURL + 'Subscriptions/GetSubscriptionsByCustomerIdwithAuthID/'; //Deepa : 804
    this.appConfig.DeleteAzureAuth = this.appConfigdata.QumuliAPIURL + 'Subscriptions/AzureAuthDelete/'; //Deepa : 804
    this.appConfig.ListPatchesDetails = this.appConfigdata.QumuliAPIURL + 'Assets/ListPatchesDetails/';
    this.appConfig.DashboardUDR = this.appConfigdata.QumuliAPIURL + 'Dashboard/GetAWSUDR';
    this.appConfig.DashboardNSG = this.appConfigdata.QumuliAPIURL + 'Dashboard/GetAWSNSG';
    this.appConfig.EventsSubscription = this.appConfigdata.QumuliAPIURL + 'Subscriptions/GetEventsSubscription';
    this.appConfig.GetScanList = this.appConfigdata.QumuliAPIURL + 'Subscriptions/GetScanList/';
    this.appConfig.InsertProjectUsers = this.appConfigdata.QumuliAPIURL + 'ProjectUsers/SaveProjectUsers';
    this.appConfig.listsslcertificate = this.appConfigdata.QumuliAPIURL + 'SSLExpiry/GetSSLExpiry/';
    this.appConfig.savesslcertificate = this.appConfigdata.BaseURL + 'SSLExpiry/CreateSSLExpiry';
    this.appConfig.deletesslcertificate = this.appConfigdata.QumuliAPIURL + 'SSLExpiry/DeleteSSLExpiry/';
    this.appConfig.listProjects = this.appConfigdata.QumuliAPIURL + 'Project/';
    this.appConfig.validateUser = this.appConfigdata.QumuliAPIURL + 'Project/GetCheckUsers/';
   // SaveCustomTag: string;
  }

  //AssetsInformation

}

export class AppConfigdata {

  BaseURL: string;
  Azurefunction: string;
  Azurefeature: string;
  AzureActionfunction: string;
  AWSfunction: string;
  AWSRemediatefunction: string;
  M365function: string;
  AWSActionfunction: string;
  GCPfunction: string;
  GCPActionfunction: string;
  QumuliAPIURL: string;
  AppClientId: string;
  UXBaseURL: string;
  SchedulerUIURL: string;
  Recaptcha3_siteKey: string;
  JiraURL: string;
  JiraClientId: string;
  JiraClientSecret: string;
}


export class AppConfig {
  Azurefeature: string;
  DashboardUDR: string;
  DashboardNSG: string;
  GetVMPatches: string;
  VMInstallPatches: string;
  DefaultTag: string;
  JiraReturnUrl: string;
  JiraStatusTicket: string;
  JiraUpdatetTicket: string;
  JiraGetProject: string;
  JiraAccount: string;
  oneclickaudit: string;
  EmailAlert: string;
  CronExpression: string;
  GetScandifference: string;
  GetAssetsdifference: string;
  AssetsSearch: string;
  AssetsSelectedInformation: string;
  AssetsInformation: string;
  Midpdf: string;
  AdminSubscription: string;
  Providercompliance: string;
  AdminProvider: string;
  DashboardCompliance: string;
  GetAnalysisAssets_Drilldowninfo: string;
  DashboardAnalysisAssetsdrilldown: string;
  DashboardAnalysisPlatform: string;
  DashboardAnalysisAssets: string;
  UserAuditLogs: string;
  UserAuditLogsSettings: string;
  AuditLogsSettings: string;
  Updateuser: string;
  ApiUrl: string;
  Azurefunction: string;
  AWSfunction: string;
  AWSRemediatefunction: string;
  M365function: string;
  GCPfunction: string;
  GCPActionfunction: string;
  logout: string;
  UXBaseURL: string;
  SubscriptionScanHistory: string;
  resetpassword: string;
  ResetPwdbyEmail: string;
  EmailConfirmation: string;
  userlogin: string;
  PostAuthcode: string;
  saveSubscriptions: string;
  getCustomerIdByAspnetUserid: string;
  saveSubscriptionScan: string;
  calculateControlScorePerSubscription: string;
  saveSubscriptionCompliance: string;
  getSubscriptionControlGroupDetails: string;
  reports: string;
  AppClientId: string;
  //AppSecerateId: string;
  dashbaordtoppanel: string;
  dashboardDataNumberOfScan: string;
  dashboardAssetLevelCompliance: string;
  ScanHistory: string;
  dashboardMonthWisePassVsFail: string;
  dashboardComplianceWised: string;
  Industries: string;
  LicenseFeatureList: string;
  Customer: string;
  OtpMid: string;
  ForgetPassword: string;
  GetLicenses: string;
  GetIndustries: string;
  PostCustomer: string;
  PostManagement: string;
  ComplianceStandards: string;
  subscriptionscanning: string;
  reportmonthly: string;
  subscriptionscanstatus: string;
  controlgroupdetails: string;
  GetSubscriptionbyUserid: string;
  GetSubscriptionScanReport: string;
  GetTokenApi: string;
  GetCustomerLicFeatureApi: string;
  GetFeatureMasterApi: string;
  GetSubscriptionbyCustomerId: string;
  GetSubscriptionAWSbyCustomerId: string;
  GetSubScanbySubId: string;
  PostScanAction: string;
  GetScanAction: string;
  GetScanActions: string;
  AzureActionfunction: string;
  AWSActionfunction: string;
  PostSubscriptionScanAction: string;
  SaveSubscriptionCompliance: string;
  SecondStepGraph: string;
  ProviderMasters: string;
  ResendOtpApi: string;
  ValidateEmail: string;
  ValidateName: string;
  PostSaveSubscription: string;
  PutSaveSubscription: string;
  DeleteSubscriptions: string;
  StorageAccounts: string;
  AspNetRoles: string;
  AspNetUserRoles: string;
  GetUsersByCustomerId: string;
  PostCustomeruserdetails: string;
  PutCustomeruserdetails: string;
  DeleteUserId: string;
  MyComplianceControl: string;
  GetProviderMaster: string;
  GetRegion: string;
  SaveCompliancestandard: string;
  UpdateCompliancestandard: string;
  DeleteCompliancestandard: string;
  SaveMyCompliance: string;
  DeleteMyCompliance: string;
  GetComplianceControls: string;
  SaveComplianceControls: string;
  GetMyComplianceMaster: string;
  GetSelectedCompliance: string;
  DeleteMyComplianceMaster: string;
  CustomDeleteMyComplianceMaster: string;
  GetCustomerUrl: string;
  GetCustomerListUrl: string;
  GetSubscriptionGCPbyCustomerId: string;
  GetSubscriptionM365byCustomerId: string;
  PostGCPConnection: string;
  SendCustEmail: string;
  GetUserRole: string;
  Post: string;
  Customers: string;
  Authorization: string;
  SchedulerUIURL: string;
  PostAuditLogs: string;
  GetAuditLogs: string;
  JiraIssueCreation: string;
  LogCreation: string;
  SaveQuartzTrigger: string;
  GetQuartzTrigger: string;
  QuartzSubscriptions: string;
  QuartzSubscriptionsDeletion: string;
  QuartzSubscriptionsUpdate: string;
  GetCustomerLicense: string;
  GetCustomerLicenseContinuous: string;
  GetTimeZoneList: string;
  Save_CustomerSettings: string;
  Get_CustomerSettings: string;
  GetTimezone_CustomerSettings: string;
  Update_CustomerSettings: string;
  GetSubscriptionExists: string;
  PostUpdateCustomerLicense: string;
  PostGetCustomerLicense: string;
  MarketPlace: string;
  MidSupportSendMail: string;
  Recaptcha3_siteKey: string;
  GetCustomerLicensesForDisplay: string;
  M365PostSaveSubscription: string;
  AGGridTheme: string;
  AGGridRowHeight: number;
  AGGridDefaultHeight: number;
  GetAssetsDetails: string;
  GetDashboardCustomTag: string;
  GetDashboardCustomTagInformation: string;
  GetCustomTag: string;
  SaveCustomTag: string;
  DeleteCustomTag: string;
  GetPolicyVoilationDetails: string;
  CloudAssets: string;
  SupportMail: string;
  FeedbackMail: string;
  NetworkInventoryCountConfig: string;
  NetworkInventoryConfig: string;
  NetworkInventoryUDRConfig: string;
  OneClickAudit: string;
  GetDashboard_cloud: string;
  GetDashboard_Cloud_Compliance: string;
  GetDashboard_Compliance_Subscription: string;
  JiraURL: string;
  JiraClientId: string;
  JiraClientSecret: string;
  ListAzureAuth: string; //Deepa : 804
  AzureAuthPostSaveSubscription: string; //Deepa : 804
  AzureAuthPostUpdateSubscription: string; //Deepa : 804
  GetSubscriptionbyCustomerIdwithAuthId: string; //Deepa : 804
  DeleteAzureAuth: string; //Deepa : 804
  GetIpaddress: string;
  ListPatchesDetails: string;
  EventsSubscription: string;
  IcamSubscription: string;
  listsslcertificate: string;
  savesslcertificate: string;
  deletesslcertificate: string;
  GetScanList: string;
  GetProjectUser: string;
  listProjects: string;
  InsertProjectUsers: string;
  validateUser: string;
}
