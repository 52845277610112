import { Component, OnInit } from '@angular/core';


@Component({
  //moduleId: module.id,
  selector: 'securityinformation-cmp',
  templateUrl: 'securityinformation.component.html',
})

export class SecurityInformationComponent implements OnInit {
  

  constructor() {
    
  }

  ngOnInit() {
   
  }


}
