import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable ,  throwError } from 'rxjs';
import { JwtService } from '../jwt.service';
import { catchError } from 'rxjs/operators';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { ScanDetailsForControls, SubscriptionDetails, SubscriptionStatus, CodeVal, CodeValue, ResponseJira, JiraRequestProject, JiraResponseProject } from '../../model/subscriptionCompliance.model';
import { Providermaster, AspNetRoles } from '../../model/providermaster';
import { AppConfigService, AppConfig } from '../../config/AppConfig.config';
import { Validateuser } from '../../model/validateuser.model';

@Injectable({
  providedIn: 'root'
})


@Injectable()
export class AzureADService {

  private config: AppConfig;

  constructor(
    private http: HttpClient, private apiService: ApiService,
    private appConfigService: AppConfigService
  ) { this.config = appConfigService.appConfig; }


  GetAzureAD(codeVal: CodeVal): Observable<SubscriptionDetails[]>{
    return this.apiService.post(this.config.PostManagement, codeVal)
      .pipe(map(
        data => data
      ));
  }


  GetJiraToken(codeVal: CodeVal): Observable<ResponseJira> {
    return this.apiService.post(this.config.JiraAccount, codeVal)
      .pipe(map(
        data => data
      ));
  }

  JiraGetProject(codeVal: JiraRequestProject): Observable<JiraResponseProject[]> {
    return this.apiService.post(this.config.JiraGetProject, codeVal)
      .pipe(map(
        data => data
      ));
  }

  GetAzureADPost(codeVal: CodeValue): Observable<any> {

    return this.apiService.post(this.config.Post, codeVal)
      .pipe(map(
        data => data
      ));
  }


  GetProviderMaster(): Observable<Providermaster[]> {
    return this.apiService.get(this.config.ProviderMasters)
      .pipe(map(
        data => data
      ));
  }


  GetAspNetRoles(): Observable<AspNetRoles> {

    return this.apiService.get(this.config.AspNetRoles + sessionStorage.getItem("Suserrole"))
      .pipe(map(
        data => data
      ));
  }

  //GetAspNetUserRoles(Userid:string): Observable<any> {

  //  return this.apiService.get(this.config.AspNetRoles + Userid)
  //    .pipe(map(
  //      data => data
  //    ));
  //}

}
