import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable ,  throwError } from 'rxjs';
import { JwtService } from '../jwt.service';
import { retry,catchError } from 'rxjs/operators';
import { API_CONFIG, AppConfig } from '../../config/app.config';
import { ParamsComplianceQumuliScore, ComplianceQumuliScore, DashboardDataCls } from '../../model/reports';
import { AppConfigService } from '../../config/AppConfig.config';


@Injectable()
export class ApiService {

  private config: AppConfig;
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private jwtService: JwtService,
    private appConfigService: AppConfigService
  ) {
    this.config = appConfigService.appConfig;


    

  }

  httpOptions = {
    headers: new HttpHeaders({      
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Bearer ' + sessionStorage.getItem("Stoken")
    })
  }


   
  AzurehttpOptions = {
    headers: new HttpHeaders({
      'content': "application/json",
      'x-functions-key': 'vPhjm6/HCR5U26N/KIZzhz7cWRcJ45p11z8ktvC9otP3Ik2UD6w8Fw=='
    })
  }

  private formatErrors(error: any) {
    //console.log(error);
    return  throwError(error.error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    var httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + sessionStorage.getItem("Stoken")
      })
    }
    return this.http.get(path, httpOptions1)
      .pipe(retry(1),catchError(this.formatErrors));
  }

  getwithouttoken(path: string, body: object = {}): Observable<any> {
    
    return this.http.get(path, body)
      .pipe(retry(1), catchError(this.formatErrors));
  }

  AzureApiget(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(path, this.AzurehttpOptions)
      .pipe(retry(1),catchError(this.formatErrors));
  }


  Normalget(path: string): Observable<any> {
    return this.http.get(path)
      .pipe(retry(1), catchError(this.formatErrors));
  }


  put(path: string, body: Object = {}): Observable<any> {
    var httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + sessionStorage.getItem("Stoken")
      })
    }
    return this.http.put(
      path,
      JSON.stringify(body), httpOptions1
    ).pipe(retry(1),catchError(this.formatErrors));
  }

  post(path: string, body: any): Observable<any> {

    var httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + sessionStorage.getItem("Stoken")
      })
    }
    return this.http.post(
      path,
      JSON.stringify(body), httpOptions1      
    ).pipe(retry(1),catchError(this.formatErrors));
  }

  sendpost(path: string, body: any): Observable<any> {
    return this.http.post(
      path,
      JSON.stringify(body)
    ).pipe(retry(1), catchError(this.formatErrors));
  }

   AzureApipost(path: string, body: any): Observable<any> {
    return this.http.post(
      path,
      JSON.stringify(body), this.AzurehttpOptions
    ).pipe(retry(1),catchError(this.formatErrors));
  }

  delete(path): Observable<any> {
    var httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + sessionStorage.getItem("Stoken")
      })
    }
    return this.http.delete(
      path, httpOptions1
    ).pipe(retry(1),catchError(this.formatErrors));
  }
}
