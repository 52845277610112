import { Injectable, Inject } from '@angular/core';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { AppConfigService, AppConfig } from '../../config/AppConfig.config';
import { LogService } from '../../service/childservice/log.service';

@Injectable({
  providedIn: 'root'
})

export class ContinuousService {

  private config: AppConfig;

  constructor(private apiService: ApiService, private appConfigService: AppConfigService, private logService: LogService) {
    this.config = appConfigService.appConfig;
    this.GetStartup();
  }

  OneClickAudit() {

    var oOneClickAuditRequest = new OneClickAuditRequest();
    oOneClickAuditRequest.CustomerId = sessionStorage.getItem("Scustomerid");
    oOneClickAuditRequest.UserId = sessionStorage.getItem("Scustomerid");
    oOneClickAuditRequest.AWSScan = this.config.AWSfunction;
    oOneClickAuditRequest.AzureScan = this.config.Azurefunction;
    oOneClickAuditRequest.GCPScan = this.config.GCPfunction;
    oOneClickAuditRequest.M365Scan = this.config.M365function;

    try {
      return this.apiService.post(this.config.OneClickAudit, oOneClickAuditRequest)
        .pipe(map(
          data => data
        ));
    } catch (error) {
      this.logService.LogError(error, "", "Continuous_Service");

    }

  }

  GetStartup() {

    var startupUser = new StartupUser();
    startupUser.CustomerId = sessionStorage.getItem("Scustomerid");
    startupUser.UserId = sessionStorage.getItem("Suserid");
  }

  Save_TRIGGERS(jobdata: Jobdata): Observable<Jobdata> {
    try {
      return this.apiService.post(this.config.SaveQuartzTrigger, jobdata)
        .pipe(map(
          data => data
        ));
    } catch (error) {
      this.logService.LogError(error, "", "Continuous_Service");

    }
  }

  Get_TRIGGERS(): Observable<SelectQuartzTriggerJobData[]> {
    let CustomerId = sessionStorage.getItem("Scustomerid");
    let UserId = sessionStorage.getItem("Suserid");
    try {
      return this.apiService.get(this.config.GetQuartzTrigger + CustomerId + "/" + UserId)
        .pipe(map(
          data => data
        ));
    } catch (error) {
      this.logService.LogError(error, "", "Continuous_Service");

    }
  }


  Update_TRIGGERS(qrtzTriggersJobStatus: QrtzTriggersJobStatus) {

    try {
      return this.apiService.post(this.config.QuartzSubscriptionsUpdate, qrtzTriggersJobStatus)
        .pipe(map(
          data => data
        ));
    } catch (error) {
      this.logService.LogError(error, "", "Continuous_Service");

    }
  }


  Delete_TRIGGERS(TriggerName: string, TriggerGroup: string) {

    try {
      return this.apiService.delete(this.config.QuartzSubscriptionsDeletion + TriggerName + "/" + TriggerGroup)
        .pipe(map(
          data => data
        ));
    } catch (error) {
      this.logService.LogError(error, "", "Continuous_Service");

    }
  }

  GetQuartzSubscriptions(Subscriptiontype: string, ComplianceId: string): Observable<any> {
    try {
      let CustomerId = sessionStorage.getItem("Scustomerid");
      let userid = sessionStorage.getItem("Suserid");
      return this.apiService.get(this.config.QuartzSubscriptions + CustomerId + "/" + userid + "/" + Subscriptiontype + "/" + ComplianceId)
        .pipe(map(
          data => data
        ));

    } catch (error) {
      this.logService.LogError(error, "", "Continuous_Service");

    }
  }

  GetCustomerLicense(): Observable<CustomerLicense[]> {
    try {

      let CustomerId = sessionStorage.getItem("Scustomerid");//+'/'+sessionStorage.getItem("Suserid")



      return this.apiService.get(this.config.GetCustomerLicense + CustomerId)
        .pipe(map(
          data => data
        ));

    } catch (error) {
      this.logService.LogError(error, "", "GetCustomerLicense");

    }
  }

  GetCustomerLicenseForDisplay(): Observable<CustomerLicense[]> {
    try {

      let CustomerId = sessionStorage.getItem("Scustomerid");



      return this.apiService.get(this.config.GetCustomerLicensesForDisplay + CustomerId)
        .pipe(map(
          data => data
        ));

    } catch (error) {
      this.logService.LogError(error, "", "GetCustomerLicense");

    }
  }

  GetCustomerLicenseContinuous(): Observable<CustomerLicense[]> {
    try {

      let CustomerId = sessionStorage.getItem("Scustomerid");
      let UserId = sessionStorage.getItem("Suserid");



      return this.apiService.get(this.config.GetCustomerLicenseContinuous + CustomerId+"/"+UserId)
        .pipe(map(
          data => data
        ));

    } catch (error) {
      this.logService.LogError(error, "", "GetCustomerLicense");

    }
  }


  GetCustomerSettings(): Observable<CustomerSettings[]> {
    try {
      let CustomerId = sessionStorage.getItem("Scustomerid");

      return this.apiService.get(this.config.Get_CustomerSettings + CustomerId + "/" + sessionStorage.getItem("Suserid"))
        .pipe(map(
          data => data
        ));

    } catch (error) {
      this.logService.LogError(error, "", "GetCustomerLicense");

    }
  }

  GetCustomerSettingsUsingCustomerId(id: string): Observable<CustomerSettings[]> {
    try {
      return this.apiService.get(this.config.GetTimezone_CustomerSettings + id)
        .pipe(map(
          data => data
        ));

    } catch (error) {
      this.logService.LogError(error, "", "GetCustomerSettingsUsingCustomerId");

    }
  }


  GetAdminSubscriptionCustomerId(id: string): Observable<pAdminSubscriptionDetails[]> {
    try {

      var paramCustomer = new ParamCustomer();
      paramCustomer.CustomerId = id;

      return this.apiService.post(this.config.AdminSubscription, paramCustomer)
        .pipe(map(
          data => data
        ));

    } catch (error) {
      this.logService.LogError(error, "", "GetAdminSubscriptionCustomerId");

    }
  }


  GetAdminProvider(id: string): Observable<pAdminProviderDetails[]> {
    try {
      var paramCustomer = new ParamCustomer();
      paramCustomer.CustomerId = id;


      return this.apiService.post(this.config.AdminProvider, paramCustomer)
        .pipe(map(
          data => data
        ));

    } catch (error) {
      this.logService.LogError(error, "", "GetpAdminProviderDetails");

    }
  }



  Get_SubscriptionExists(customerSettings: QuartzSubscriptionExists): Observable<QrtzTriggers[]> {
    try {

      return this.apiService.get(this.config.GetSubscriptionExists + customerSettings.CustomerId + "/" + customerSettings.SubscriptionId + "/" + customerSettings.ComplianceId)
        .pipe(map(
          data => data
        ));

    } catch (error) {
      this.logService.LogError(error, "", "Get_SubscriptionExists");

    }
  }

  Save_CustomerSettings(customerSettings: CustomerSettings): Observable<CustomerSettings> {
    try {

      return this.apiService.post(this.config.Save_CustomerSettings, customerSettings)
        .pipe(map(
          data => data
        ));

    } catch (error) {
      this.logService.LogError(error, "", "GetCustomerLicense");

    }
  }

  Update_CustomerSettings(customerSettings: CustomerSettings): Observable<CustomerSettings> {
    try {

      return this.apiService.put(this.config.Update_CustomerSettings + customerSettings.settingsId, customerSettings)
        .pipe(map(
          data => data
        ));

    } catch (error) {
      this.logService.LogError(error, "", "GetCustomerLicense");

    }
  }



}


export class pAdminProviderDetails {
  rowNo: number;
  subscriptiontype: string;
  totalSubscriptionCount: number;
}

export class pAdminSubscriptionDetails {
  subscriptionId: number;
  subscriptionName: string;
  subscriptiontype: string;
  complianceName: string;
  lastScanDate: Date;
  totalScanCount: number;
}






export class QrtzTriggersJobStatus {
  TriggerName: string;
  Status: string;

}

export class StartupUser {
  UserId: string;
  CustomerId: string;

}

export class QuartzSubscriptionExists {
  SubscriptionId: string;
  CustomerId: string;
  ComplianceId: string;
}



export class Jobdata {
  CustomerId: string;
  UserId: string;
  JobName: string;
  TriggerName: string;
  ProviderName: string;
  ComplianceName: string;
  CronType: string;
  SubscriptionName: string;
  StartDate: string;
  EndDate: string;
  TimeZoneId: string;
}


export class QrtzTriggers {
  SchedName: string;
  TriggerName: string;
  TriggerGroup: string;
  JobName: string;
  JobGroup: string;
  Description: string;
  NextFireTime: number;
  PrevFireTime: number;
  Priority: number;
  TriggerState: string;
  TriggerType: string;
  StartTime: number;
  EndTime: number;
  CalendarName: string;
  MisfireInstr: number;
  JobData: string[];
}


export class SelectQuartzTriggerJobData {
  Row_num: number;
  triggeR_NAME: string;
  ProviderName: string;
  SubscriptionName: string;
  CloudProviderName: string;
  cloudDescription: string;
  triggeR_GROUP: string;
  ComplianceName: string;
  DESCRIPTION: string;
  NEXT_FIRE_TIME: string;
  PREV_FIRE_TIME: string;
  triggeR_STATE: string;
  START_TIME: string;
  END_TIME: string;

}

export class CustomerLicense {
  custLicenseID: number;
  customerId: string;
  cloudId: string;
  cloudProviderName: string;
  cloudDescription: string;
  expiryDate: Date;
  licensename: string;
  totalDay: number;

}




export class CustomerSettings {
  settingsId: number;
  CustomerId: string;
  EmailReportTemplate: string;
  ScanningType: string;
  ComplianceLists: string;
  timeZone: string;
  dateFormat: string;
  EmailNotifications: string;
  NotificationRecipients: string;
  deferredstatus: string;
  generalSettings: string;
}


export class deferredstatus {
  isdeferredstatus: string;
  isrange: string;
  startdate: string;
  enddate: string;
}

export class ParamCustomer {

  CustomerId: string;

}


export class OneClickAuditRequest {

  CustomerId: string;
  UserId: string;
  AzureScan: string
  AWSScan: string;
  GCPScan: string;
  M365Scan: string;
         
}
