import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConfigService } from '../../config/AppConfig.config';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from '../../service/notification.service';
import { TimeZoneList } from '../../model/user.model';
import { MustMatch } from '../../commonvalidations/confirmpassword.service';


@Component({
  selector: 'editprofile-home-layout',
  templateUrl: './editprofile-layout.component.html',
  styleUrls: ['./editprofile-layout.component.scss']
})
export class EditprofileLayoutComponent implements OnInit {

 

  constructor() {

   
  }


  ngOnInit() {

    

  }
}
