
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONFIG, AppConfig, APP_CONFIG } from '../../config/app.config';
import { SubscriptionCompliance, SubscriptionScan, ScanDetailsForControls, SubscriptionStatus, SubscriptionDetails } from '../../model/subscriptionCompliance.model'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { strict } from 'assert';
import { SyncAsync } from '@angular/compiler/src/util';
import { Router } from '@angular/router';
import { ParamsComplianceQumuliScore, ComplianceQumuliScore, DashboardDataCls } from '../../model/reports';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { Providermaster } from '../../model/providermaster';
import { Compliancestandard, ScanAction, SubscriptionScanAction } from '../../model/compliancestandard';
import { AppConfigService } from '../../config/AppConfig.config';


@Injectable({
  providedIn: 'root'
})
export class prividerService {

  private config: AppConfig;


  constructor(private http: HttpClient, private router: Router, private apiService: ApiService,
    private appConfigService: AppConfigService
  ) {
    this.config = appConfigService.appConfig;

  }//, @Inject(APP_CONFIG) private config: AppConfig


  GetCustomerIdByAspnetUserid(id: string) {
    return this.apiService.get(this.config.getCustomerIdByAspnetUserid + id)
      .pipe(map(
        data => data
      ));
  }

  GetComplianceStandardList(): Observable<Compliancestandard[]> {
    return this.apiService.get(this.config.SaveCompliancestandard)
      .pipe(map(
        data => data
      ));
  }

  GetComplianceStandard(): Observable<Compliancestandard[]> {
    let sprovidermasterCloudId = sessionStorage.getItem("sprovidermasterCloudId")
    return this.apiService.get(this.config.ComplianceStandards + sprovidermasterCloudId)
      .pipe(map(
        data => data
      ));
  }

  GetComplianceStandardBaseCloudId(sprovidermasterCloudId: string): Observable<Compliancestandard[]> {
   // let sprovidermasterCloudId = sessionStorage.getItem("sprovidermasterCloudId")
    return this.apiService.get(this.config.ComplianceStandards + sprovidermasterCloudId)
      .pipe(map(
        data => data
      ));
  }


  SaveAction(scanAction: ScanAction) {
    return this.apiService.post(this.config.PostScanAction, scanAction)
      .pipe(map(
        data => data
      ));
  }

  GetAction() {
    let scanningId = sessionStorage.getItem('scanningId');
    return this.apiService.get(this.config.GetScanActions + scanningId)
      .pipe(map(
        data => data
      ));
  }


  StartActionfunction(scanAction: ScanAction, SubscriptionId: number) {
    return this.apiService.get(this.config.AzureActionfunction + "&QumuliScanId=" + scanAction.scanActionId + "&QumuliSubscriptionId=" + SubscriptionId + "&QumuliToken=" + sessionStorage.getItem("Stoken"))
      .pipe(map(
        data => data
      ));
  }


  StartAWSActionfunction(scanAction: ScanAction, SubscriptionId: number) {
    return this.apiService.get(this.config.AWSActionfunction + "&QumuliScanId=" + scanAction.scanActionId + "&QumuliSubscriptionId=" + SubscriptionId + "&QumuliToken=" + sessionStorage.getItem("Stoken"))
      .pipe(map(
        data => data
      ));
  }

  StartGCPActionfunction(scanAction: ScanAction, SubscriptionId: number) {

    return this.apiService.get(this.config.GCPActionfunction + "&QumuliScanId=" + scanAction.scanActionId + "&QumuliSubscriptionId=" + SubscriptionId + "&QumuliToken=" + sessionStorage.getItem("Stoken"))
      .pipe(map(
        data => data
      ));
  }

  public StartGCPFunction(ScanningId: string, ComplianceId: number, SubscriptionId: number) {
    let url = this.config.GCPfunction + '&ScanningId=' + ScanningId + "&ComplianceId=" + ComplianceId + "&QumuliSubscriptionId=" + SubscriptionId + "&QumuliToken=" + sessionStorage.getItem("Stoken");
  
    return this.apiService.get(url)
      .pipe(map(
        data => data
      ));


  }


  ReCreationAction(SubscriptionId: string): Observable<SubscriptionScanAction[]> {
    return this.apiService.get(this.config.PostSubscriptionScanAction + "/" + SubscriptionId)
      .pipe(map(
        data => data
      ));
  }

}
