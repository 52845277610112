import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {
      'Content-Type': 'application/json'
    };
    //const token = this.jwtService.getToken();

    //if (token) {
    //  headersConfig['Authorization'] = `Token ${token}`;
    //}

    const request = req.clone({ setHeaders: headersConfig });
    return next.handle(request);
  }
}
