import { Injectable, Inject } from '@angular/core';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { API_CONFIG, AppConfig } from '../../config/app.config';
import { User, credentials, UserLogin, UserLoginOTP, IndustryDetails, Customer, ForgetPassword, Resetpwd, Register_val, CResendOtp } from '../../model/user.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { ApiService } from './api.service';
import { AuthenticationService } from './authenticationService.service';
import { Observable } from 'rxjs';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
   
    //if (currentUser) {
    //  // authorised so return true
    //  return true;
    //}


 if (sessionStorage.getItem("Suserrole") == undefined || sessionStorage.getItem("Suserrole") == "" || sessionStorage.getItem("Suserrole") == null) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }

    if (sessionStorage.getItem("Suserrole") === "1d6c254d-4512-4005-b8fc-cd01a9ac2af4") {




      if (state.url === "/profile" || state.url === "/compliance/users" || state.url === "/compliance/dashboard" || state.url === "/compliance/Drilldown" || state.url === "/compliance/MonthlyHistory" || state.url === "/compliance/subscriptionlist"
        || state.url === "/compliance/ScanHistory" || state.url === "/compliance/ScanHistory/Scanreport" || state.url === "/compliance/ScanHistory/AwsScanreport" || state.url === "/compliance/ScanHistory/gcpreport" || state.url === "/compliance/settings" || state.url === "/compliance/profile" ||
        state.url === "/compliance/ChangePassword" || state.url === "/compliance/provider/AWS" || state.url === "/compliance/provider/Azure" || state.url === "/compliance/reports") {
        return true;
      }
      else {
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
      }

    }
    else {
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }


}
