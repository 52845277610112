import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SupportSidebarComponent } from './supportsidebar.component';

@NgModule({
    imports: [ RouterModule, CommonModule ],
  declarations: [SupportSidebarComponent ],
  exports: [SupportSidebarComponent ]
})

export class SupportSidebarModule {}
