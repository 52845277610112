import { Injectable, Inject } from '@angular/core';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { API_CONFIG, AppConfig, APP_CONFIG } from '../../config/app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class StartService {


  constructor(private apiService: ApiService) {

  }


  GetStartup(): Observable<StartupValues> {

    let BaseURL = document.getElementsByTagName('base')[0].href;

    return this.apiService.get(BaseURL + "api/Startup/GetStartupValues")
      .pipe(map(
        data => data
      ));
  }

}


export class StartupValues {
  azurefunction: string;
  azureActionfunction: string;
  awSfunction: string;
  awsActionfunction: string;
  gcPfunction: string;
  gcpActionfunction: string;
  qumuliAPIURL: string;
  baseURL: string;
}



