import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable ,  throwError } from 'rxjs';
import { JwtService } from '../jwt.service';
import { catchError } from 'rxjs/operators';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { ScanDetailsForControls, SubscriptionDetails, SubscriptionStatus, CodeVal, CodeValue, NotificationModel } from '../../model/subscriptionCompliance.model';
import { Providermaster, AspNetRoles } from '../../model/providermaster';
import { AppConfigService, AppConfig } from '../../config/AppConfig.config';
import { Validateuser } from '../../model/validateuser.model';

@Injectable({
  providedIn: 'root'
})


@Injectable()
export class SupportService {

  private config: AppConfig;

  constructor(
    private http: HttpClient, private apiService: ApiService,
    private appConfigService: AppConfigService
  ) { this.config = appConfigService.appConfig; }



  SendSupportMail(support: Support): Observable<SupportStatusModel> {
   
    return this.apiService.post(this.config.MidSupportSendMail, support)
      .pipe(map((data => data)));
  }

  //GetPDFScanReportDetails(Notification: Support): Observable<any> {

    
  //  return this.apiService.post(this.config.Midpdf, Notification)
  //    .pipe(map((data => data)));
  //}



}

export class Support {
  Name: string;
  EmailId: string;
  Message: string;
}


export class SupportStatusModel {
  status: string; 
  message: string;
}


