import { Component, OnInit } from '@angular/core';

import { AgGridAngular } from 'ag-grid-angular';
import 'ag-grid-enterprise';

import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey('CompanyName=Qonsult Systems Pte Ltd,LicensedApplication=Qumuli,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-012601,ExpiryDate=28_December_2021_[v2]_MTY0MDY0OTYwMDAwMA==dab80514417b29315ad9a3817bcbf52d');


@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  currentYear: any;

  constructor() {
  }


  ngOnInit() {
    this.currentYear = new Date();

  }


  

}
