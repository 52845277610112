
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONFIG, AppConfig, APP_CONFIG } from '../../config/app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { User } from '../../model/user.model';
import { AppConfigService } from '../../config/AppConfig.config';



@Injectable({
  providedIn: 'root'
})
export class UserLoginsService {

  private config: AppConfig;

  constructor(private http: HttpClient, private router: Router, private apiService: ApiService, private appConfigService: AppConfigService) {
    this.config = appConfigService.appConfig;
  }//, @Inject(APP_CONFIG) private config: AppConfig

  GetUsersByCustomerId(): Observable<User[]> {
    let CustomerId = sessionStorage.getItem("Scustomerid");

    return this.apiService.get(this.config.GetUsersByCustomerId + CustomerId + '/' + sessionStorage.getItem("Suserid") + '/' + sessionStorage.getItem("sprojectID"))
      .pipe(map(
        data => data
      ));
  }
  GetUsersBySelectedCustomerId(CustId: string): Observable<User[]> {
    let CustomerId = CustId;

    return this.apiService.get(this.config.GetUsersByCustomerId + CustomerId + '/' + sessionStorage.getItem("Suserid") + '/' + sessionStorage.getItem("sprojectID"))
      .pipe(map(
        data => data
      ));
  }
  GetCustomers(): Observable<any> {
    return this.apiService.get(this.config.GetCustomerListUrl).pipe(map(data => data));
  }

  DeleteUsersId(Suserid: string): Observable<any> {
    return this.apiService.get(this.config.DeleteUserId + Suserid)
      .pipe(map(
        data => data
      ));
  }



}
