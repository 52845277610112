import { Injectable, Inject } from '@angular/core';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { User, credentials, UserLogin, UserLoginOTP, IndustryDetails, Customer, ForgetPassword, Resetpwd, Register_val, CResendOtp, LicenseDetails, TimeZoneList, FeatureState } from '../../model/user.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { ApiService } from './api.service';
import { StartService } from './startup.service';
import { Observable } from 'rxjs';
import { AppConfigService, AppConfig } from '../../config/AppConfig.config';


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  sharedData: User;
  BaseURL = "";
  private config: AppConfig


  constructor(private http: HttpClient, private appConfigService: AppConfigService,
    private startService: StartService, private apiService: ApiService, private router: Router
  ) {
    this.config = appConfigService.appConfig;
  }

  //public string Encrypt(strval ) {
  //  let key = CryptoJS.enc.Utf8.parse('8080808080808080');
  //  let iv = CryptoJS.enc.Utf8.parse('8080808080808080');

  //  let encryptedlogin = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(val), key,
  //    { keySize: 128 / 8, iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });

  //  return encryptedlogin;
  //}



  MidLoginUser(user: credentials) {
    let key = CryptoJS.enc.Utf8.parse('8080808080808080');
    let iv = CryptoJS.enc.Utf8.parse('8080808080808080');

    let encryptedlogin = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(user.Password), key,
      { keySize: 128 / 8, iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });

    user.Password = String(encryptedlogin);

    return this.apiService.post(this.config.userlogin, user)
      .pipe(map(
        data => data

      ));
  }


  GetCustomerIdByAspnetUserid(id: string) {

    return this.apiService.get(this.config.getCustomerIdByAspnetUserid + id)
      .pipe(map(
        data => data
      ));
  }

  ValidateEmail(Email: string) {
    return this.apiService.get(this.config.ValidateEmail + "/" + Email)
      .pipe(map(
        data => data
      ));

  }

  GetProjectUsers(UserId: string) {
    return this.apiService.get(this.config.GetProjectUser  + UserId)
      .pipe(map(
        data => data
      ));

  }

  ValidateName(Name: string) {
    return this.apiService.get(this.config.ValidateName + "/" + Name)
      .pipe(map(
        data => data
      ));

  }

  CustomerTokenDetails(id: string) {
    return this.apiService.get(this.config.GetTokenApi + id)
      .pipe(map(
        data => data
      ));
  }

  CustomerLicFeatureDetails(id: string) {
    return this.apiService.get(this.config.GetCustomerLicFeatureApi + id)
      .pipe(map(
        data => data
      ));
  }

  GetFeatureMaster(): Observable<FeatureState[]> {
    return this.apiService.get(this.config.GetFeatureMasterApi)
      .pipe(map(
        data => data
      ));
  }

  GetOTPValidation(userLoginOTP: UserLoginOTP) {
    return this.apiService.post(this.config.PostAuthcode, userLoginOTP)
      .pipe(map(
        data => data
      ));
  }


  GetIndustries(): Observable<IndustryDetails[]> {
    return this.apiService.get(this.config.Industries)
      .pipe(map(
        data => data
      ));
  }

  GetLicenseFeatures() {
    return this.apiService.get(this.config.LicenseFeatureList)
      .pipe(map(
        data => data
      ));
  }

  GetCustomer(): Observable<Customer> {
    let userid = sessionStorage.getItem("Scustomerid");
    return this.apiService.get(this.config.Customer + userid)
      .pipe(map(
        data => data
      ));
  }

  SentUserOtp(id: string): Observable<any> {
    return this.apiService.getwithouttoken(this.config.OtpMid + id)
      .pipe(map(
        data => data
      ));
  }


  UpdateCustomer(customer: Customer): Observable<Customer> {
    let userid = sessionStorage.getItem("Scustomerid");
    return this.apiService.put(this.config.Customer + customer.customerId, customer)
      .pipe(map(
        data => data
      ));
  }


  Updateuser(): Observable<Customer> {
    let userid = sessionStorage.getItem("Suserid");//sessionStorage.setItem("Suserid"
    return this.apiService.get(this.config.Updateuser + userid)
      .pipe(map(
        data => data
      ));
  }


  ForgetPassword(forgetpassword: ForgetPassword) {
    return this.apiService.post(this.config.ForgetPassword, forgetpassword)
      .pipe(map(
        data => data
      ));
  }

  ResendOTP(cResendOtp: CResendOtp) {
    return this.apiService.post(this.config.ResendOtpApi, cResendOtp)
      .pipe(map(
        data => data
      ));
  }

  ResetPwd(resetpwd: Resetpwd) {

    let key = CryptoJS.enc.Utf8.parse('8080808080808080');
    let iv = CryptoJS.enc.Utf8.parse('8080808080808080');

    let encryptedlogin = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(resetpwd.Password), key,
      { keySize: 128 / 8, iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });

    let encryptedtext = String(encryptedlogin);

    resetpwd.Password = encryptedtext;

    resetpwd.ConfirmPassword = encryptedtext;

    return this.apiService.post(this.config.ResetPwdbyEmail, resetpwd)
      .pipe(map(
        data => data
      ));
  }

  PostCustomer(regval: Register_val) {
    let key = CryptoJS.enc.Utf8.parse('8080808080808080');
    let iv = CryptoJS.enc.Utf8.parse('8080808080808080');

    let encryptedlogin = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(regval.Password), key,
      { keySize: 128 / 8, iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    regval.Password = String(encryptedlogin);
    regval.ConfirmPassword = String(encryptedlogin);

    return this.apiService.post(this.config.PostCustomer, regval)
      .pipe(map(
        data => data
      ));
  }

  GetUserRole(email: ForgetPassword) {
    return this.apiService.sendpost(this.config.GetUserRole, email)
      .pipe(map(
        data => data
      ));
  }
  getIPAddress(): Observable<GetIPAddress> {
    return this.apiService.get(this.config.GetIpaddress)
      .pipe(map(
        data => data
      ));

  };

  getCurrentIPAddress(): Observable<any> {
    return this.apiService.Normalget("https://api.ipify.org/?format=json")
      .pipe(map(
        data => data
      ));

  };
  GetLicenseDetails(): Observable<LicenseDetails[]> {
    return this.apiService.get(this.config.GetLicenses)
      .pipe(map(
        data => data
      ));
  }

  GetIndustriesDetails(): Observable<IndustryDetails[]> {
    return this.apiService.get(this.config.GetIndustries)
      .pipe(map(
        data => data
      ));
  }


  GetTimeZoneDetails(): Observable<TimeZoneList[]> {
    return this.apiService.get(this.config.GetTimeZoneList)
      .pipe(map(
        data => data
      ));
  }


  PostMarketPlace(request_MarketPlace: Request_MarketPlace): Observable<Response_MarketPlace> {

    return this.apiService.post(this.config.MarketPlace, request_MarketPlace)
      .pipe(map(
        data => data
      ));

  }



}

export class GetIPAddress {
  ipAddress: string;
}

export class Request_MarketPlace {
  MarketPlaceToken: string;
  EmailId: string;
  UserToken: string;
  
}

export class Response_MarketPlace {
  Id: string;
  emailId: string;
  Status: string;
  

}
