import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'Authorization-home-layout',
  templateUrl: './authorization-layout.component.html',
  styleUrls: ['./authorization-layout.component.scss']
})
export class AuthorizationLayoutComponent implements OnInit {

  ngOnInit() { }
}
