import { Injectable, Inject } from '@angular/core';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { User, credentials, UserLogin, UserLoginOTP, IndustryDetails, Customer, ForgetPassword, Resetpwd, Register_val, CResendOtp } from '../../model/user.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppConfigService, AppConfig } from '../../config/AppConfig.config';



@Injectable({
  providedIn: 'root'
})
export class CustomTagService {

  private config: AppConfig

  constructor(
    private router: Router, private appConfigService: AppConfigService, private apiService: ApiService,

  ) { this.config = appConfigService.appConfig; }

  GetRegion(): Observable<Region[]> {
    return this.apiService.get(this.config.GetRegion)
      .pipe(map(
        data => data
      ));
  }
  GetDashBoardAssetsTags(): Observable<pCustomTag[]> {

    return this.apiService.get(this.config.GetDashboardCustomTag + sessionStorage.getItem("Scustomerid") + "/" + sessionStorage.getItem("Suserid") + "/"+sessionStorage.getItem("sprojectID"))
      .pipe(map(
        data => data
      ));
  }

  GetDashBoardAssetsTagsInformation(paramDashboardAssetTag: ParamDashboardAssetTag): Observable<DashboardAssetTag[]> {
    paramDashboardAssetTag.UserId = sessionStorage.getItem("Suserid");
    paramDashboardAssetTag.ProjectId = sessionStorage.getItem("sprojectID");
    return this.apiService.post(this.config.GetDashboardCustomTagInformation, paramDashboardAssetTag)
      .pipe(map(
        data => data
      ));
  }


  GetAssetTags(param: ParamCustomTags): Observable<CustomTag[]> {
        
    return this.apiService.post(this.config.GetCustomTag, param)
      .pipe(map(
        data => data
      ));
  }


  SaveAssetTags(customTag: CustomTag): Observable<CustomTag> {  

    return this.apiService.post(this.config.SaveCustomTag, customTag)
      .pipe(map(
        data => data
      ));
  }


  DeleteTags(customTag: string): Observable<CustomTag> {

    return this.apiService.delete(this.config.DeleteCustomTag + customTag)
      .pipe(map(
        data => data
      ));
  }


}


export class ParamCustomTags {
  CustomerId: string;
  AssetName: string;
  subscriptionId: number;
  ResourceGroupname: string;
}

export class CustomTag {
  tagId: string;
  tagName: string;
  customerId: string;
  subscriptionId: number;
  resourceGroupname: string;
  assetName: string;
  assetId: string;
  tagtype: boolean;

}

export class pCustomTag {
  rowNo: string;
  tagName: string;
}

export class DashboardAssetTag {
  rowNo: string;
  subscriptionName: string;
  resourceGroupName: string;
  assetName: string;
  pass: string;
  fail: string;
  nA: string;
}

export class ParamDashboardAssetTag {
  CustomerId: string;
  TagId: string;
  UserId: string;
  ProjectId: string;
}

export class Region {
  regionId: string;
  regionName: string;
  status: string;
  checked: false;
}
       


//export class ParamCustomTags {
//  CustomerId: string;
//  AssetName: string;
//  subscriptionId: string;
//  ResourceGroupname: string;
//}






