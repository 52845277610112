
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONFIG, AppConfig } from '../../config/app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { strict } from 'assert';
import { SyncAsync } from '@angular/compiler/src/util';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { GCP, statusGCP } from '../../model/gcp.model';
import { AppConfigService } from '../../config/AppConfig.config';

@Injectable({
  providedIn: 'root'
})
export class GCPService {

  private config: AppConfig;


  constructor(private http: HttpClient, private router: Router, private apiService: ApiService, private appConfigService: AppConfigService) { this.config = appConfigService.appConfig; }

  ConnectGCPConnection(param: GCP): Observable<statusGCP> {

    //let url = "https://accounts.google.com/o/oauth2/auth?scope=email%20profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Ftasks%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fadmin.directory.user%20https%3a%2f%2fwww.googleapis.com%2fauth%2fdevstorage.full_control+https%3a%2f%2fwww.googleapis.com%2fauth%2fcompute+https%3a%2f%2fwww.googleapis.com%2fauth%2fcloud-platform+https%3a%2f%2fwww.googleapis.com%2fauth%2fiam+&state=offline&redirect_uri=https://localhost:44304/compliance/gcplogin&response_type=code&client_id=472808513012-kqb0kjp07aligva7kqfcrahvct47opbr.apps.googleusercontent.com&approval_prompt=force&access_type=offline";

    //window.location.href = url;

    return this.apiService.post(this.config.PostGCPConnection, param)
      .pipe(map(
        data => data
      ));


    //return this.apiService.get(url)
    //  .pipe(map(
    //    data => data
    //  ));
  }




  
}
