import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { exception } from 'console';

@Injectable({
  providedIn: 'root'  // <- ADD THIS
})

export class ParamsComplianceQumuliScore {
  Year: number;
  CustomerId: string;
  Option: string;
  MonthName:string;
  UserId: string;
  ProjectId: string;
}

export class ParamsQumuliPlatform { 
  CustomerId: string;
  CloudProviderTypeId: string;
  UserId: string;
  ProjectId: string;
}


export class ParamsQumuliScore {
  SubGroupId: number;
  CustomerId: string;
  Cloudtype: string;

}


export class ParamsCloud {
  CustomerId: string;
  SubGroupId: number;  
  param: string;

}

export class ParamsComplianceQumuliScoreHistroy {

  Year: number;
  CustomerId: string;
  Option: string;
  MonthName: string;
}

export class ComplianceQumuliScore {
  qscore: number;
  nameValue: string;
  typeValue: string;

}

export class DashboardDataCls {
  totalSubscriptions: number;
  qScore: number;
  noControlsPassed: number;
  noControlsScanned: number;
}

export class DashboardDataNumberOfScan {
  rowNo: number;
  scanningId:string;
  complianceName: string;
  passCnt: number;
  failCnt: number;
  manualCnt: number;
  noControlsScanned: number
  subscriptionName: string;
  subscriptiontype: string;
}


export class GetDashboard_cloud {
  rowNo: number;
  maxScanningDate: string;
  subscriptiontype: string;
  cloudid: string;
  totalControls: number;
  passCnt: number;
  failCnt: number;
  manualCnt: number;
}


export class GetDashboard_Cloud_Compliance {
  rowNo: number;
  maxScanningDate: string;
  complianceId: string;
  complianceName: string;
  totalControls: number;
  passCnt: number;
  failCnt: number;
  manualCnt: number;
}


export class ParamsQumuliSubscription {
  CustomerId: string;
  ComplianceId: number;
}


//export class ParamsQumuliPlatform {
//  CustomerId: string;
//  CloudProviderTypeId: string;
//}




export class GetDashboard_Compliance_Subscription {
  rowNo: number;
  scanningId: string;
  subscriptionName: string;
  subscriptiontype: string;
  complianceName: string;
  maxScanningDate: string;
  totalControls: number;
  passCnt: number;
  failCnt: number;
  manualCnt: number;
}




export class GetAnalysisAssets {
  rowNo: number; 
  controlGroupName: string;
  subControlGroupId: number;
  controlGroupIcon: string;
  controlGroupDesc: string;
  passCnt: number;
  failCnt: number;
  manualCnt: number;
  
}







export class GetAnalysisAssetsdrilldown {
  rowNo: number;
  subscriptionName: string;
  scanningId: string;
  cloudProviderName: string;
  resourceGroupName: string;
  name: string;
  databaseName: string;
  complianceName: string;
  controlDescription: string;
  subControlGroupId: number;

  result: string;
}





export class GetAnalysisAssetsdrilldowninfo {
  rowNo: number;
  subscriptionName: string;
  scanningId: string;
  subscriptiontype: string;
  scanningDate: string;
  controlGroupDesc: string;
}





export class DashboardCompliance {
  rowNo: number;
  scanningId: string;
  complianceId: number;
  complianceName: string;
  passCnt: number;
  failCnt: number;
  manualCnt: number;
  totalControls: number;
}




export class ComplianceBasedData {
  rowNo: number;
  monthValue: string;
  passCnt: number;
  failCnt: number;
}
export class DashboardAssetLevelCompliance {
  rowNo: number;
  controlGroupName: string;
  qScore: number;
  complianceName: string;
  scanningDate: string
}
export class NetworkInventory {
  rowNo: number;
  parm: string;
  parmvalue: string;
  parent1: string;
  parent2: string
}


export class NetworkInventoryCount {
  rowNo: number;
  groupName: string;
  cntResource: number;
}

export class NetworkInventoryRpt {
  rowNo: number;
  nSG: string;
  resourceName: string;
  description: string;
  protocol: string;
  sourcePortRange: string;
  destinationPortRange: string;
  sourceAddressPrefix: string;
  sourceAddressPrefixes: string;
  sourceApplicationSecurityGroups: string;
  destinationAddressPrefix: string;
  destinationAddressPrefixes: string;
  destinationApplicationSecurityGroups: string;
  //sourcePortRanges: string;
  //destinationPortRanges: string;
  access: string;
  priority: string;
  direction: string;
  provisioningState: string;
  subscription: string;
}

export class NetworkInventoryRptUDR {
  rowNo: number;
  uDR: string;
  resourceName: string;
  addressPrefix: string;
  nextHopType: string;
  nextHopIpAddress: string;
  provisioningState: string;
  subscription: string;
}
