import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppConfigService, AppConfig } from '../../config/AppConfig.config';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgGridService {

  private config: AppConfig

  constructor(private apiService: ApiService, private appConfigService: AppConfigService) {
    this.config = appConfigService.appConfig;
  }

  SetHeight(RowCount: number): number {

    let gridHeight: number;
    try {

      if (RowCount > 10) {
        gridHeight = 550;
      }
      else {
        gridHeight = (RowCount * this.config.AGGridRowHeight) + this.config.AGGridDefaultHeight;
      }

      



    } catch (e) {

    }

    return gridHeight;

  }



}




