
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONFIG, AppConfig, APP_CONFIG } from '../../config/app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { strict } from 'assert';
import { SyncAsync } from '@angular/compiler/src/util';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { User, CustomerStatus } from '../../model/user.model';
import { ProviderMaster } from '../../model/providermaster.model';
import { AppConfigService } from '../../config/AppConfig.config';


@Injectable({
  providedIn: 'root'
})
export class ProviderMastersService {

  private config: AppConfig;

  constructor(private http: HttpClient, private router: Router, private apiService: ApiService,
    private appConfigService: AppConfigService) {
    this.config = appConfigService.appConfig;
  }



  GetProviderMaster(): Observable<ProviderMaster[]> {
    return this.apiService.get(this.config.GetProviderMaster)
      .pipe(map(
        data => data
      ));
  }


}
