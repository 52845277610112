
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { strict } from 'assert';
import { SyncAsync } from '@angular/compiler/src/util';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { User, CustomerStatus } from '../../model/user.model';
import { MyCompliances, StandardsControl, MyComplianceMasterControl, GetMyComplianceMaster, ListProviderComplianceControl } from '../../model/mycompliances.model';
import { Compliancestandard, MyCompliancecontrol, SubscriptionDetails } from '../../model/compliancestandard';
import { AppConfigService, AppConfig } from '../../config/AppConfig.config';


@Injectable({
  providedIn: 'root'
})
export class MyCompliancesService {

  private config: AppConfig;

  constructor(private http: HttpClient, private router: Router, private apiService: ApiService,
    private appConfigService: AppConfigService) {
    this.config = appConfigService.appConfig;
  }






  GetMyCompliances(): Observable<MyCompliances[]> {
    let customerid = sessionStorage.getItem("Scustomerid");
    let CloudId = sessionStorage.getItem("sprovidermasterCloudId")
    return this.apiService.get(this.config.MyComplianceControl + customerid + "/" + CloudId)
      .pipe(map(
        data => data
      ));
  }


  GetCompliances(CloudId: string): Observable<MyCompliances[]> {
    let customerid = sessionStorage.getItem("Scustomerid");
    //let CloudId = sessionStorage.getItem("sprovidermasterCloudId")
    return this.apiService.get(this.config.MyComplianceControl + customerid + "/" + CloudId)
      .pipe(map(
        data => data
      ));
  }


  SaveCompliances(compliancestandard: Compliancestandard): Observable<Compliancestandard> {
    return this.apiService.post(this.config.SaveCompliancestandard, compliancestandard)
      .pipe(map(
        data => data
      ));
  }

  UpdateCompliances(compliancestandard: Compliancestandard): Observable<Compliancestandard> {
    return this.apiService.put(this.config.UpdateCompliancestandard + compliancestandard.complianceId, compliancestandard)
      .pipe(map(
        data => data
      ));
  }

  DeleteCompliances(myCompliances: MyCompliances): Observable<any> {
    return this.apiService.delete(this.config.DeleteCompliancestandard + myCompliances.complianceId)
      .pipe(map(
        data => data
      ));
  }


  SaveMyCompliances(myCompliance: MyCompliancecontrol): Observable<MyCompliancecontrol> {
    return this.apiService.post(this.config.SaveMyCompliance, myCompliance)
      .pipe(map(
        data => data
      ));
  }



  DeleteMyCompliances(myCompliances: MyCompliances): Observable<any> {
    return this.apiService.delete(this.config.DeleteMyCompliance + myCompliances.myComplianceControlId)
      .pipe(map(
        data => data
      ));
  }


  GetCompliancecontrols(): Observable<StandardsControl[]> {
    let CloudId = sessionStorage.getItem("sprovidermasterCloudId")
    return this.apiService.get(this.config.GetComplianceControls + CloudId)
      .pipe(map(
        data => data
      ));
  }


  SaveComplianceControls(myCompliances: MyComplianceMasterControl[]): Observable<any> {

    return this.apiService.post(this.config.SaveComplianceControls, myCompliances)
      .pipe(map(
        data => data
      ));
  }


  GetSelectedComplianceControls(complianceId: string): Observable<GetMyComplianceMaster[]> {
    let customerid = sessionStorage.getItem("Scustomerid");
    return this.apiService.get(this.config.GetMyComplianceMaster + customerid + "/" + complianceId)
      .pipe(map(
        data => data
      ));
  }
  GetSelectedCompliance(complianceId: string): Observable<SubscriptionDetails> {
 
    return this.apiService.get(this.config.GetSelectedCompliance + complianceId)
      .pipe(map(
        data => data
      ));
  }

  DeleteSelectedComplianceControls(myCompliances: GetMyComplianceMaster): Observable<GetMyComplianceMaster[]> {
    
    return this.apiService.delete(this.config.DeleteMyComplianceMaster + myCompliances.myComplianceMasterControlId)
      .pipe(map(
        data => data
      ));
  }

  


  CustomDeleteMyComplianceMaster(myCompliances: MyCompliances): Observable<GetMyComplianceMaster[]> {

    return this.apiService.delete(this.config.CustomDeleteMyComplianceMaster + myCompliances.myComplianceControlId)
      .pipe(map(
        data => data
      ));
  }

}
