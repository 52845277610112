
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../config/AppConfig.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { strict } from 'assert';
import { SyncAsync } from '@angular/compiler/src/util';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { User, CustomerStatus, GetScanList } from '../../model/user.model';
import { AppConfigService } from '../../config/AppConfig.config';
import { CustomerSettings } from './continuous.service';

@Injectable({
  providedIn: 'root'
})
export class MidService {

  private config: AppConfig

  constructor(private http: HttpClient, private router: Router, private apiService: ApiService, private appConfigService: AppConfigService) {
    this.config = appConfigService.appConfig;
  }

  GetScanList(param: any): Observable<GetScanList[]> {
    return this.apiService.get(this.config.GetScanList+ param)
      .pipe(map(
        data => data
      ));
  }

  IsEmpty(control: any): boolean {

    if (control === null || control === undefined || control === "") {
      return true;
    }
    else {
      return false;
    }

  }

  Getdateformat(): string {
    try {

      let dateformat = sessionStorage.getItem("dateformat")//Read date formation in session


      if (this.IsEmpty(dateformat)) { //session validation null or empty


        ////Read selected customer settings
        this.GetCustomerSettings().subscribe(data => {

          this.GetCustomerSettings();
          ////session validation null or empty
          if (this.IsEmpty(data[0].dateFormat)) {

            //if date format is null default settings
            sessionStorage.setItem("dateformat", "MMM dd, yyyy HH:mm");
            return "MMM dd, yyyy HH:mm";
          }
          else {

            //Get from DB selected customer
            sessionStorage.setItem("dateformat", data[0].dateFormat);
            return data[0].dateFormat;
          }


        })

      } else {
        return dateformat;
      }




    } catch (error) { return "MMM dd, yyyy HH:mm"; }
  }

  GetCustomerSettings(): Observable<CustomerSettings[]> {
    try {

      let CustomerId = sessionStorage.getItem("Scustomerid");

      return this.apiService.get(this.config.Get_CustomerSettings + CustomerId)
        .pipe(map(
          data => data
        ));

    } catch (error) {
      //this.logService.LogError(error, "", "GetCustomerLicense");

    }
  }

  UserCreation(param: any): Observable<CustomerStatus> {
    return this.apiService.post(this.config.PostCustomeruserdetails, param)
      .pipe(map(
        data => data
      ));
  }

  ProjectUserCreation(param: ParamProject): Observable<ParamProject> {
    return this.apiService.post(this.config.InsertProjectUsers, param)
      .pipe(map(
        data => data
      ));
  }

  UserUpdate(projectid:string,param: any): Observable<any> {
    return this.apiService.put(this.config.PutCustomeruserdetails + projectid, param)
      .pipe(map(
        data => data
      ));
  }


  CustomerUpdate(customerId: string, param: any): Observable<any> {
    return this.apiService.put(this.config.GetCustomerUrl+"/"+ customerId, param)
      .pipe(map(
        data => data
      ));
  }

  SendCustomerMail(param: any): Observable<any> {
    return this.apiService.sendpost(this.config.SendCustEmail, param)
      .pipe(map(
        data => data
      ));
  }

  GetCustomerUserToken(customerId: string): Observable<any> {
    return this.apiService.get(this.config.GetTokenApi+ customerId)
      .pipe(map(
        data => data
      ));
    }

    CustomerLicUpdate(customerId: string, param: any): Observable<any> {
        return this.apiService.post(this.config.PostUpdateCustomerLicense, param)
            .pipe(map(
                data => data
            ));
    }

  CustomerLicGet(customerId: string): Observable<any> {
    return this.apiService.get(this.config.PostGetCustomerLicense + customerId)
            .pipe(map(
                data => data
            ));
    }
}


export class ParamProject {
  CustomerId: string;
  UserEmail: string;
  ProjectId: string;
}
