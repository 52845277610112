
import { Component, OnInit, Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'  // <- ADD THIS
})


export class SubscriptionCompliance {
  subscriptionComplianceId: number;
  subscriptionId: number;
  complianceId: number;
  lockDown: boolean;
  lockDownReason: string;
}


export class SubscriptionScan {
  scanningId: string;
  scanningDate: Date;
  scanningStatus: string;
  scanningType: string;
  scanningComponent: string;
  noControlsPassed: number;
  noControlsFailed: number;
  scanStart: Date;
  scanEnd: Date;
  noControlsScanned: number;
  subComplianceId: number;
  subscriptionId: number;
  initializedby: string;
  result: string;
  subscriptionName: string;
  userName: string;
  complianceName: string;
  complianceType: string;
  totalCompliance: number;
  totalProcessedCompliance: number;
  complianceDetailsView: string;
}

export class ResponseJira {
  jiraId: string;
  jiratoken: string;
  refresh_token: string;
  jiraUrl: string;
  jiraImage: string;
  project: string;
  code: string;
  status: string;
}

export class JiraRequestProject {
  id: string;
  token: string;
}

export class JiraResponseProject {
  expand: string;
  self: string;
  id: string;
  key: string;
  name: string;
  siteurl: string;
  siteid: string;
  sitename: string;
  avatarUrls: string;
}



export class ScanDetailsForControls {
  rownum: number;
  controlGroupId: string;
  controlGroupName: string;
  controlTitle: string;
  ticketId: string;
  ticketstatus: string;
  jiraactionstatus: string;
  controlDescription: string;
  classification: string;
  scanStart: string;
  scanEnd: string;
  automated: string;
  status: string;
  error: string;
  controlAssets: string;
  propertyName: string;
  inputRequired: string;
  controlAction: string;
  region: string;
  resourceGroupName: string;
  name: string;
  result: string;
  scanAction: string;
  controlVersion: string;
  storageAccountname: string;
  mappedControlsName: string;
  controlAssetsDetails: string;
}

export class NetworkDetails {
  id: boolean;
  type: string;
  Protocol: string;
  Priority: string;
  Access: string;
  SourceAddress: boolean;
  DestinationAddress: string;
  Name: string;
  ResourceGroupName: string;
  AssetName: string;
  NetworkSecurityGroupsname: string;
  Direction: string;
}

export class ScanActionControls {
  requiredcontrol: boolean;
  controltype: string;
  valuetype: string;
  defaultvalue: string;
  controltitle: string;
  requiredstorageAccount: boolean;
  minvalue: string;
  maxvalue: string;
}

export class ScanAWSActionControls {
  requiredcontrol: boolean;
  controltype: string;
  valuetype: string;
  defaultvalue: string;
  controltitle: string;
  secondcontrol: string;
  secondcontroltitle: string;
  thirdcontrol: string;
  thirdcontroltitle: string;
  minvalue: string;
  maxvalue: string;
}

export class NotificationModel {
  EmailTo: string;
  SubscriptionID: string;
  AccessToken: string;
}

export class NotificationStatusModel {
  Status: string;
  Message: string;
}


export class SubscriptionStatus {
  subscriptionId: string;
  status: string;
}


export class LocalizableString {
  Value: string;
  LocalizedValue: string;
}

export class ActivityLog {
  eventTimestamp: Date;
  resourceGroupName: string;
  caller: string;
  authorization: SenderAuthorization;
  operationName: LocalizableString;
  status: string;
  nextLink: string;
}

export class EventData {
  authorization: SenderAuthorization;
  caller: string;
  description: string;
  eventTimestamp: string;
  claims: any;
  subscriptionId: string;
  status: LocalizedString;
  eventName: LocalizedString;
}

export class SenderAuthorization {
  action: string;
  // role: string;
  scope: string;
}

export class LocalizedString {
  localizedValue: string;
  value: string;
}

export class StorageAccounts {
  ROWNUMBER: number;
  StorageName: string;
}

export class SubscriptionDetails {
  subscriptionId: number;
  subscriptionGuid: string;
  subscriptionName: string;
  accountId: number;
  subscriptionUserId: string;
  objectId: string;
  expiryDateTime: string;
  customerId: string;
  accessToken: string;
  userId: string;
  tenantId: string;
  subscriptiontype: string;
  isExists: boolean = false;
  projectId: string;
  region: string;
}


export class SubscriptionScanHistory {
  rowNo: number;
  scanningId: string;
  subscriptionName: string;
  scanDate: string;
  scanningDate: string;
  scanEnd: string;
  subscriptionGUID: string;
  complianceName: string;
  scanningStatus: string;
  subscriptiontype: string;
}


export class ComplianceMonthBasedData {
  rowNo: number;
  scanningId: string;
  subscriptionName: string;
  scanningDate: string;
  noControlsScanned: string;
  noControlsPassed: string;
  noControlsFailed: string;
}


export class CodeVal {
  code: string;
  CustomerId: string;
  UserId: string;
  CustomerToken: string;

}

export class viewlogging {
  statuslog: string;
}

export class CodeValue {
  code: string;
}
