import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SecurityInformationComponent } from './securityinformation.component';

@NgModule({
    imports: [ RouterModule, CommonModule ],
  declarations: [SecurityInformationComponent ],
  exports: [SecurityInformationComponent ]
})


export class SecurityInformationModule {}
