import { Component, OnInit, Renderer2, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { ROUTES } from '../../sidebar/sidebar.component';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ResetService } from '../../service/childservice/reset.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Providermaster } from '../../model/providermaster';
import { AuditLogs } from '../../model/user.model';
import { GlobalService } from '../../service/global.service';
import { GetAnalysisAssets, ParamsCloud, ParamsQumuliPlatform } from '../../model/reports';
import { ReportsService } from '../../service/childservice/reports.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { param } from 'jquery';
import { pGetSubscriptionAssets } from '../../service/childservice/assets.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from '../../service/notification.service';

@Component({
  //moduleId: module.id,
  selector: 'supportnavbar-cmp',
  templateUrl: 'supportnavbar.component.html',
  styleUrls: ['supportnavbar.css']
})

export class SupportNavbarComponent implements OnInit {
  private listTitles: any[];
  private subControlGroupId: number;
  location: Location;
  private nativeElement: Node;
  private toggleButton;
  private sidebarVisible: boolean;
  private userdisplayname: string;
  private provider_master: Providermaster[];
  private GetSubscriptionAssets: pGetSubscriptionAssets[];
  public GetAnalysisAsset: GetAnalysisAssets[];
  showProfile: boolean = false;

  frmFeedback: FormGroup;
  frmSupport: FormGroup;

  submitted1: boolean;
  submitted: boolean;

  isqumuliadmin: boolean;


  keyword = 'assetname';
  pagename: string = "template";
  formatter = (x: { name: string }) => x.name;

  public isCollapsed = true;
  @ViewChild("navbar-cmp", { static: false }) button;
  @ViewChild('Feedback', { static: true }) Feedback: TemplateRef<any>;
  @ViewChild('Support', { static: true }) Support: TemplateRef<any>;
  @ViewChild('GetTouch', { static: true }) GetTouchform: TemplateRef<any>;
  @ViewChild('auto', { static: false }) auto;


  constructor(private toaster: NotificationService,protected fb: FormBuilder, private http: HttpClient, private resetService: ResetService, location: Location, private renderer: Renderer2,
    private element: ElementRef, private router: Router, private globalService: GlobalService, private reportsService: ReportsService, private modalService: NgbModal) {
    this.location = location;
    this.submitted = false;
    this.submitted1 = false;
    this.isqumuliadmin = (sessionStorage.getItem("qumulilogin")) == "0" ? true : false;
      
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
    this.userdisplayname = sessionStorage.getItem("Sdisplayname");
    if (sessionStorage.getItem("Suserrole") == "BD9921C8-947D-4262-B44F-A6A0FF69835D") {
      this.showProfile = true;
    }
    else {
      this.showProfile = false;
    } 

    this.subControlGroupId = 27;
    this.PopulateAssets('');

  }

  GetAssetsSubgroup(e) {
    this.subControlGroupId = e;
    this.PopulateAssets('');


  }

  SaveFeedback() {
    this.submitted1 = true;

    var paramsFeedback = new ParamsFeedback();
    paramsFeedback.CustomerId = sessionStorage.getItem("Scustomerid");
    paramsFeedback.Name = sessionStorage.getItem("Sdisplayname");
    paramsFeedback.Phonenumber = this.frmFeedback.value.Phonenumber;
    paramsFeedback.Comments = this.frmFeedback.value.Comments;

    if (paramsFeedback.Phonenumber == undefined || paramsFeedback.Phonenumber == "" || paramsFeedback.Phonenumber == null) {
      paramsFeedback.Phonenumber ="0";
    }

    if (this.frmFeedback.invalid) {
      return;
    }
    else {
      this.submitted1 = false;
      this.reportsService.SendFeedback(paramsFeedback).subscribe(data => {
        if (data.statusMessage == "success") {
          this.toaster.showSuccess("Thank you for feedback and we will revert back", "");
          this.submitted1 = false;
          this.frmFeedback.controls.Phonenumber.setValue("");
          this.frmFeedback.controls.Comments.setValue("");        
          this.modalService.dismissAll();

        }
      });
    }


  }

  SaveSupport() {
    this.submitted1 = true;

    var paramsSupport = new ParamsSupport();
    paramsSupport.CustomerId = sessionStorage.getItem("Scustomerid");
    paramsSupport.Name = sessionStorage.getItem("Sdisplayname");
    paramsSupport.Problem = this.frmSupport.value.Problem;
    paramsSupport.SupportType = this.frmSupport.value.SupportType;

    if (this.frmSupport.invalid) {
      return;
    }
    else {
      this.submitted1 = false;
      this.reportsService.SendSupportTeam(paramsSupport).subscribe(data => {
        if (data.statusMessage == "success") {
          this.toaster.showSuccess("Thank you for feedback and we will revert back", "");
          this.frmSupport.controls.Problem.setValue("");
          this.frmSupport.controls.SupportType.setValue("");
          this.modalService.dismissAll();
          this.submitted1 = false;
        }
        
      });
    }

  }

  PopulateAssets(param) {
  
  }
  feedbackform() {
    this.open(this.Feedback);
  }

  support() {
    this.open(this.Support);
  }

  GetTouch() {
    this.open(this.GetTouchform);
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("fired"));
  }


  


  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    var navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
    });
    this.GetAnalysisAsset = [];
    this.userdisplayname = sessionStorage.getItem("Sdisplayname");


    this.frmSupport = this.fb.group({
      SupportType: ['', [Validators.required]],
      Problem: ['', [Validators.required]]
    });


    this.frmSupport = this.fb.group({
      SupportType: ['', [Validators.required]],
      Problem: ['', [Validators.required]]
    });

    this.frmFeedback = this.fb.group({
      Phonenumber: ['', [Validators.pattern('^[0-9]+$')]],
      Comments: ['', [Validators.required]]
    });


    this.frmSupport.controls.SupportType.setValue("Technical");


  }

  get f() { return this.frmSupport.controls; }

  get f1() {
    return this.frmFeedback.controls;
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }
    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return 'Scan';
  }

  


  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      //this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }
  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);

    html.classList.add('nav-open');
    if (window.innerWidth < 991) {
      mainPanel.style.position = 'fixed';
    }
    this.sidebarVisible = true;
  };
  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = '';
      }, 500);
    }
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  };
  collapse() {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName('nav')[0];
    // console.log(navbar);
    if (!this.isCollapsed) {
      navbar.classList.remove('navbar-transparent');
      navbar.classList.add('bg-white');
    } else {
      navbar.classList.add('navbar-transparent');
      navbar.classList.remove('bg-white');
    }

  }

  logout() {
   

    this.router.navigate(['/login/login']);

  }

}




export class ParamsSupport {
  CustomerId: string;
  Name: string;
  SupportType: string;
  Problem: string;

}


export class ParamsFeedback {
  CustomerId: string;
  Name: string;
  Phonenumber: string;
  Comments: string;

}

export class EmailStatus {
  statusMessage: string;
  
}



