import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SupportNavbarComponent } from './supportnavbar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbComponent } from '../../pages/breadcrumb/breadcrumb.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [RouterModule, CommonModule, NgbModule, AutocompleteLibModule, FormsModule, ReactiveFormsModule],
  declarations: [SupportNavbarComponent],
  exports: [SupportNavbarComponent ]
})

export class SupportNavbarModule {}
