import { Component, OnInit, Renderer2, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { ROUTES } from '../../sidebar/sidebar.component';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ResetService } from '../../service/childservice/reset.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Providermaster } from '../../model/providermaster';
import { AuditLogs } from '../../model/user.model';
import { GlobalService } from '../../service/global.service';
import { GetAnalysisAssets, ParamsCloud, ParamsQumuliPlatform } from '../../model/reports';
import { ReportsService } from '../../service/childservice/reports.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { param } from 'jquery';
import { pGetSubscriptionAssets } from '../../service/childservice/assets.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from '../../service/notification.service';
import { ProjectsList } from 'src/app/model/compliancestandard';

@Component({
  //moduleId: module.id,
  selector: 'navbar-cmp',
  templateUrl: 'navbar.component.html',
  styleUrls:['navbar.css']
})

export class NavbarComponent implements OnInit {
  private listTitles: any[];
  private subControlGroupId: number;
  location: Location;
  private nativeElement: Node;
  private toggleButton;
  private sidebarVisible: boolean;
  private userdisplayname: string;
  private projectlist: ProjectsList[];
  private projectId: string;
  private projectLocation: string;
  private provider_master: Providermaster[];
  private GetSubscriptionAssets: pGetSubscriptionAssets[];
  public GetAnalysisAsset: GetAnalysisAssets[];
  showProfile: boolean = false;
  showProjectList: boolean;
  frmFeedback: FormGroup;
  frmSupport: FormGroup;

  submitted1: boolean;
  submitted: boolean;

  isqumuliadmin: boolean;
  test: boolean;


  keyword = 'assetname';
  pagename: string = "template";
  formatter = (x: { name: string }) => x.name;

  public isCollapsed = false;
  @ViewChild("navbar-cmp", { static: false }) button;
  @ViewChild('Feedback', { static: true }) Feedback: TemplateRef<any>;
  @ViewChild('Support', { static: true }) Support: TemplateRef<any>;
  @ViewChild('Profile', { static: true }) Profile: TemplateRef<any>;
  @ViewChild('auto', { static: false }) auto;


  constructor(private toaster: NotificationService,protected fb: FormBuilder, private http: HttpClient, private resetService: ResetService, location: Location, private renderer: Renderer2,
    private element: ElementRef, private router: Router, private globalService: GlobalService, private reportsService: ReportsService, private modalService: NgbModal) {
      this.location = location;
    this.submitted = false;
    this.submitted1 = false;
    this.isqumuliadmin = (sessionStorage.getItem("qumulilogin")) == "0" ? true : false;
      this.test = false;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
    this.userdisplayname = sessionStorage.getItem("Sdisplayname");
    if (sessionStorage.getItem("Suserrole") == "BD9921C8-947D-4262-B44F-A6A0FF69835D") {
      this.showProfile = true;
    }
    else {
      this.showProfile = false;
    }
    this.projectLocation=this.router.url.split('/')[2];
    this.subControlGroupId = 27;
    this.PopulateAssets('');

  }

  GetAssetsSubgroup(e) {
    this.subControlGroupId = e;
    this.PopulateAssets('');


  }

  SaveFeedback() {
    this.submitted1 = true;

    var paramsFeedback = new ParamsFeedback();
    paramsFeedback.CustomerId = sessionStorage.getItem("Scustomerid");
    paramsFeedback.Name = sessionStorage.getItem("Sdisplayname");
    paramsFeedback.Phonenumber = this.frmFeedback.value.Phonenumber;
    paramsFeedback.Comments = this.frmFeedback.value.Comments;

    if (paramsFeedback.Phonenumber == undefined || paramsFeedback.Phonenumber == "" || paramsFeedback.Phonenumber == null) {
      paramsFeedback.Phonenumber ="0";
    }

    if (this.frmFeedback.invalid) {
      return;
    }
    else {
      this.submitted1 = false;
      this.reportsService.SendFeedback(paramsFeedback).subscribe(data => {
        if (data.statusMessage == "success") {
          this.toaster.showSuccess("Thank you for feedback and we will revert back", "");
          this.submitted1 = false;
          this.frmFeedback.controls.Phonenumber.setValue("");
          this.frmFeedback.controls.Comments.setValue("");        
          this.modalService.dismissAll();

        }
      });
    }


  }

  SaveSupport() {
    this.submitted1 = true;

    var paramsSupport = new ParamsSupport();
    paramsSupport.CustomerId = sessionStorage.getItem("Scustomerid");
    paramsSupport.Name = sessionStorage.getItem("Sdisplayname");
    paramsSupport.Problem = this.frmSupport.value.Problem;
    paramsSupport.SupportType = this.frmSupport.value.SupportType;

    if (this.frmSupport.invalid) {
      return;
    }
    else {
      this.submitted1 = false;
      this.reportsService.SendSupportTeam(paramsSupport).subscribe(data => {
        if (data.statusMessage == "success") {
          this.toaster.showSuccess("Thank you for feedback and we will revert back", "");
          this.frmSupport.controls.Problem.setValue("");
          this.frmSupport.controls.SupportType.setValue("");
          this.modalService.dismissAll();
          this.submitted1 = false;
        }
        
      });
    }

  }

  PopulateAssets(param) {
    var oParamsCloud = new ParamsCloud();
    oParamsCloud.CustomerId = sessionStorage.getItem("Scustomerid");
    oParamsCloud.param = param;
    oParamsCloud.SubGroupId = this.subControlGroupId;
    this.reportsService.ToppanelAssetsReport(oParamsCloud).subscribe(data => {
      this.GetSubscriptionAssets = data;
      //console.log(data);
    })
  }
  feedbackform() {
    this.open(this.Feedback);
  }

  support() {
    this.open(this.Support);
  }
  profile() {
    this.open(this.Profile);
  }


  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("fired"));
  }
  selectEvent(item) {
    // do something with selected item
    sessionStorage.setItem("subscriptionName", item.subscriptionName);
    sessionStorage.setItem("Resourcename", item.assetname);

    this.auto.clear();

    this.router.navigate(['/compliance/Assetsempty']);

    this.delay(200).then(any => {
      //your task after delay.
      this.router.navigate(['/compliance/assetsdetails']);
    });
    

  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something when input is focused
  }


  ngOnInit() {

    this.showProjectList = true;
    if (sessionStorage.getItem("userRole") !=null){
      if (sessionStorage.getItem("userRole").toString().toLowerCase() == "qumulimaster") {
        this.showProjectList = false;        
      }
    }
    if (sessionStorage.getItem("lUserRole") == "QumuliMaster") {
      this.showProjectList = false;
    }
    this.projectLocation=this.router.url.split('/')[2];
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    var navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
    });
    this.GetAnalysisAsset = [];
    this.userdisplayname = sessionStorage.getItem("Sdisplayname");

    this.frmSupport = this.fb.group({
      SupportType: ['', [Validators.required]],
      Problem: ['', [Validators.required]]
    });

    this.frmFeedback = this.fb.group({
      Phonenumber: ['', [Validators.pattern('^[0-9]+$')]],
      Comments: ['', [Validators.required]]
    });


    this.frmSupport.controls.SupportType.setValue("Technical");

    this.AssetsLoad();
    this.ProjectLoad();
    
  }

  get f() { return this.frmSupport.controls; }

  get f1() {
    return this.frmFeedback.controls;
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }
    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return 'Scan';
  }
  ChangeProject(e){
    sessionStorage.setItem("sprojectID", e);
    this.router.navigate(['/compliance/dashboard']);
     this.ProjectDetails(e,true);
    //  
  }
  ProjectLoad() {

    this.reportsService.DashboardProjects().subscribe(data => {
      this.projectlist = data;
      
     if(sessionStorage.getItem("sprojectID") != undefined && sessionStorage.getItem("sprojectID") != null &&
      sessionStorage.getItem("sprojectID") != ""){
      this.projectId = sessionStorage.getItem("sprojectID");
      var selProj = data.find(x => x.projectId === this.projectId);
      if(selProj==undefined){
        this.projectId = data[0]["projectId"];
        sessionStorage.setItem("sprojectID", data[0]["projectId"]);
        this.ProjectDetails(data[0]["projectId"],true);
      }
      if(sessionStorage.getItem("sprojectName")=="" || sessionStorage.getItem("sprojectName")==null){
        this.ProjectDetails(this.projectId,true);
      }
      }
      else{
        this.projectId = data[0]["projectId"];
        sessionStorage.setItem("sprojectID", data[0]["projectId"]);
        this.ProjectDetails(data[0]["projectId"],true);
        
      }

    }, error => {

    });
  }

  ProjectDetails(id,doRefresh) {

    this.reportsService.ProjectDetails(id).subscribe(data => {
      if(data.length>0){
      sessionStorage.setItem("sprojectName", data[0]["projectName"]);
      }else{
        sessionStorage.setItem("sprojectName", "");
      }
      //sessionStorage.setItem("sUserName", data[0]["user"]);
    if(doRefresh){
      window.location.reload();
    }
    }, error => {

    });
  }
  AssetsLoad() {

    let paramsQumuliPlatform = new ParamsQumuliPlatform();
    paramsQumuliPlatform.CustomerId = sessionStorage.getItem("Scustomerid");
    paramsQumuliPlatform.CloudProviderTypeId = "cloud";

    this.reportsService.DashboardPlatformAssets(paramsQumuliPlatform).subscribe(data => {
      this.GetAnalysisAsset = data;

    }, error => {

    });
  }


  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      //this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }
  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);

    html.classList.add('nav-open');
    if (window.innerWidth < 991) {
      mainPanel.style.position = 'fixed';
    }
    this.sidebarVisible = true;
  };
  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = '';
      }, 500);
    }
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  };
  collapse() {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName('nav')[0];
    // console.log(navbar);
    if (!this.isCollapsed) {
      navbar.classList.remove('navbar-transparent');
      navbar.classList.add('bg-white');
    } else {
      navbar.classList.add('navbar-transparent');
      navbar.classList.remove('bg-white');
    }

  }

  logout() {
    //let auditlogs = new AuditLogs();
    //auditlogs.action = "Login Status";
    //auditlogs.auditlog = "Logged out successfully";
    //auditlogs.userid = sessionStorage.getItem("Suserid");
    //auditlogs.customerid = sessionStorage.getItem("Scustomerid");

    //this.globalService.PostAuditLogs(auditlogs).subscribe(result => {

    //});
    this.resetService.logout();

    this.router.navigate(['/login']);

  }

}




export class ParamsSupport {
  CustomerId: string;
  Name: string;
  SupportType: string;
  Problem: string;

}


export class ParamsFeedback {
  CustomerId: string;
  Name: string;
  Phonenumber: string;
  Comments: string;

}

export class EmailStatus {
  statusMessage: string;
  
}



