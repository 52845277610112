
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { strict } from 'assert';
import { SyncAsync } from '@angular/compiler/src/util';
import { Router } from '@angular/router';
import { ParamsComplianceQumuliScore, ComplianceQumuliScore, DashboardDataCls } from '../../model/reports';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { Providermaster } from '../../model/providermaster';
import { Compliancestandard, ScanAction, SubscriptionScanAction, sslcertificate } from '../../model/compliancestandard';
import { SubscriptionCompliance, SubscriptionScan, ScanDetailsForControls, SubscriptionStatus, SubscriptionDetails } from '../../model/subscriptionCompliance.model'
import { AppConfigService, AppConfig } from '../../config/AppConfig.config';


@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService {

  private config: AppConfig;

  constructor(private http: HttpClient, private router: Router, private apiService: ApiService, private appConfigService: AppConfigService) {
    this.config = appConfigService.appConfig;

  }//, @Inject(APP_CONFIG) private config: AppConfig


  DeleteSubscriptions(subscriptionDetails: SubscriptionDetails) {

    return this.apiService.get(this.config.DeleteSubscriptions + subscriptionDetails.subscriptionId)
      .pipe(map(
        data => data
      ));
  }

  Get(subscriptionDetails: SubscriptionDetails) {

    return this.apiService.get(this.config.DeleteSubscriptions + subscriptionDetails.subscriptionId)
      .pipe(map(
        data => data
      ));
  }

  GetComplianceStandard(): Observable<Compliancestandard[]> {
    let sprovidermasterCloudId = sessionStorage.getItem("sprovidermasterCloudId")
  /* console.log(this.config.ComplianceStandards + sprovidermasterCloudId);*/
    return this.apiService.get(this.config.ComplianceStandards + sprovidermasterCloudId)
      .pipe(map(
        data => data
      ));
  }


  DeleteSSLCertificate(SSID) {

    return this.apiService.delete(this.config.deletesslcertificate + SSID)
      .pipe(map(
        data => data
      ));
  }

  GetListSSLCertificate(): Observable<sslcertificate[]> {
    let customerid = sessionStorage.getItem("Scustomerid");
    /* console.log(this.config.ComplianceStandards + sprovidermasterCloudId);*/
    return this.apiService.get(this.config.listsslcertificate + customerid+'/'+ sessionStorage.getItem("Suserid")+'/'+ sessionStorage.getItem("sprojectID"))
      .pipe(map(
        data => data
      ));
  }


  SaveSSLCertificate(osslcertificate: sslcertificate) {
    osslcertificate.customerId = sessionStorage.getItem("Scustomerid");
    /* console.log(this.config.ComplianceStandards + sprovidermasterCloudId);*/
    return this.apiService.post(this.config.savesslcertificate, osslcertificate)
      .pipe(map(
        data => data
      ));
  }

  
  DeleteAzureAuth(subscriptionDetails: SubscriptionDetails) {

    return this.apiService.get(this.config.DeleteAzureAuth + subscriptionDetails.subscriptionId)
      .pipe(map(
        data => data
      ));
  }
}
