import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { SubscriptionCompliance, SubscriptionScan, ScanDetailsForControls, SubscriptionStatus, SubscriptionDetails } from '../../model/subscriptionCompliance.model'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { strict } from 'assert';
import { SyncAsync } from '@angular/compiler/src/util';
import { Router } from '@angular/router';
import { ParamsComplianceQumuliScore, ParamsComplianceQumuliScoreHistroy, ComplianceQumuliScore, DashboardDataCls, DashboardDataNumberOfScan, DashboardAssetLevelCompliance, ComplianceBasedData } from '../../model/reports';
import { ApiService } from './api.service';
import { SubscriptionScanHistory, ComplianceMonthBasedData } from '../../model/subscriptionCompliance.model';
import { map } from 'rxjs/operators';
import { AppConfigService, AppConfig } from '../../config/AppConfig.config';
import { ListProviderComplianceControl, ListGetAzureAuthApps } from '../../model/mycompliances.model';  //Deepa : 804
import { pGetSubscriptionAssets } from './assets.service';  //Deepa : 804
import { CustomTag, ParamCustomTags } from './customtag.service';

@Injectable({
  providedIn: 'root'
})


export class ScanHistoryService {

  private config: AppConfig;

  constructor(private http: HttpClient, private router: Router, private apiService: ApiService, private appConfigService: AppConfigService

  ) { this.config = appConfigService.appConfig; }


  GetProvidercompliance(param: string): Observable<ListProviderComplianceControl[]> {
    let customerid = sessionStorage.getItem("Scustomerid");
    let CloudId = sessionStorage.getItem("sprovidermasterCloudId")
    return this.apiService.get(this.config.Providercompliance + customerid + "/" + CloudId)
      .pipe(map(
        data => data
      ));
  }

  GetComplianceMonthBasedData(complianceQumuliScore: ParamsComplianceQumuliScoreHistroy): Observable<ComplianceMonthBasedData[]> {

    return this.apiService.post(this.config.reportmonthly, complianceQumuliScore)
      .pipe(map((data => data)));
  }

  GetSubscriptionScanHistory(): Observable<SubscriptionScanHistory[]> {
    let Scustomerid = sessionStorage.getItem("Scustomerid");
    var userid = sessionStorage.getItem("Suserid");
    return this.apiService.get(this.config.ScanHistory + Scustomerid + "/" + userid +"/"+sessionStorage.getItem("sprojectID"))
      .pipe(map((data => data)));
  }


  GetSubscriptionScanning(): Observable<SubscriptionScanHistory[]> {
    let userid = sessionStorage.getItem("Suserid");
    let scanningIds = JSON.parse(sessionStorage.getItem("scanningIds"));
    let strvalues = scanningIds.join(',');
    return this.apiService.get(this.config.subscriptionscanning + userid + "/" + strvalues)
      .pipe(map((data => data)));
  }
  //Deepa : 804
  GetAzureAuthApps(param: string): Observable<ListGetAzureAuthApps[]> {
    let customerid = sessionStorage.getItem("Scustomerid");
    let userid = sessionStorage.getItem("Suserid");
    let projid = sessionStorage.getItem("sprojectID");
    return this.apiService.get(this.config.ListAzureAuth + customerid + "/" + userid + "/" + projid)
      .pipe(map(
        data => data
      ));
  }


  GetDefaultTag(param: ParamCustomTags): Observable<CustomTag[]> {   
    return this.apiService.post(this.config.DefaultTag, param)
      .pipe(map(
        data => data
      ));
  }

}
