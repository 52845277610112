import { Component, OnInit } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey('CompanyName=Qonsult Systems Pte Ltd,LicensedApplication=Qumuli,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-012601,ExpiryDate=28_December_2021_[v2]_MTY0MDY0OTYwMDAwMA==dab80514417b29315ad9a3817bcbf52d');


@Component({
  selector: 'assets-home-layout',
  templateUrl: './assets-layout.component.html',
  styleUrls: ['./assets-layout.component.scss']
})
export class AssetsLayoutComponent implements OnInit {
  currentYear: any;

  ngOnInit() {
    this.currentYear = new Date();
  }
}
