import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { SupportNavbarModule } from './shared/supportnavbar/supportnavbar.module';
import { PNavbarModule } from './shared/pnavbar/pnavbar.module';
import { FixedPluginModule } from './shared/fixedplugin/fixedplugin.module';
import { RegisterLayoutComponent } from './layouts/register-layout/register-layout.component';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { SupportComponent } from './landingpages/support/support.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { SuperLayoutComponent } from './layouts/super-layout/super-layout.component';
import { LegalComponent } from './homepages/legal/legal.component';
import { SidebarModule } from './sidebar/sidebar.module';
import { SupportSidebarModule } from './supportsidebar/supportsidebar.module';
import { SecurityInformationModule } from './securityinformation/securityinformation.module';
import { PSidebarModule } from './psidebar/psidebar.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ScanComplianceService } from './service/scancompliance.service';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CoreModule } from './service/core.module';
import { AppConfigService } from './config/AppConfig.config';
import { TimezonePickerModule } from 'ng2-timezone-selector';
import { Injector } from '@angular/core';
import { UserIdleModule } from 'angular-user-idle';
import { ThemeService } from "ng2-charts";
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { EditprofileLayoutComponent } from './layouts/editprofile-layout/editprofile-layout.component';
import { ProfileLayoutComponent } from './layouts/profile-layout/profile-layout.component';
import { AuthorizationLayoutComponent } from './layouts/authorization-layout/authorization-layout.component';
import { AssetsLayoutComponent } from './layouts/assets-layout/assets-layout.component';
import { SupportLayoutComponent } from './layouts/support-layout/support-layout.component';
import { ExportAsModule } from 'ngx-export-as';
import { LicenseManager } from '@ag-grid-enterprise/core';
LicenseManager.setLicenseKey("CompanyName=Qonsult Systems Pte Ltd,LicensedApplication=Qumuli,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-012601,ExpiryDate=28_December_2021_[v2]_MTY0MDY0OTYwMDAwMA==dab80514417b29315ad9a3817bcbf52d");



export function QumuliProviderFactory(provider: AppConfigService) {
  return () => provider.load();
}

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthorizationLayoutComponent,
    HomeLayoutComponent,
    SuperLayoutComponent,
    AssetsLayoutComponent,
    EditprofileLayoutComponent,
    ProfileLayoutComponent,
    RegisterLayoutComponent,
    LegalComponent,
    SupportLayoutComponent
      ],
  imports: [
    SecurityInformationModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    CoreModule,
    ExportAsModule,
    BsDatepickerModule.forRoot(),
    RouterModule.forRoot(AppRoutes, {
      useHash: false
    }),
    PSidebarModule,
    PNavbarModule,
    SidebarModule,
    SupportSidebarModule,
    NavbarModule,
    SupportNavbarModule,
    UserIdleModule.forRoot({ idle: 600, timeout: 300, ping: 120  }),
    TimezonePickerModule,
   
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      closeButton: true,

    }),
    FooterModule,
    FixedPluginModule,
    AccordionModule.forRoot()
  ],
  providers: [ThemeService,
    AppConfigService,    
    { provide: APP_INITIALIZER, useFactory: QumuliProviderFactory, deps: [AppConfigService], multi: true }
  ],

  bootstrap: [AppComponent]
})

//idle: 600, timeout: 300, ping: 120

export class AppModule {

}

