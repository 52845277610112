import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PNavbarComponent } from './pnavbar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
    imports: [ RouterModule, CommonModule, NgbModule ],
  declarations: [PNavbarComponent],
    exports: [ PNavbarComponent ]
})

export class PNavbarModule {}
