import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-profile-layout',
  templateUrl: './profile-layout.component.html',
  styleUrls: ['./profile-layout.component.scss']
})
export class ProfileLayoutComponent implements OnInit {
  currentYear: any;

  ngOnInit() {
    this.currentYear = new Date();
  }
}
