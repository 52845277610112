import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import * as CryptoJS from 'crypto-js';
import { UserService } from '../../service/user.service';
import { LoginService } from '../../service/childservice/login.service';
import { NotificationService } from '../../service/notification.service';
import { timer } from 'rxjs';
import { User, credentials, UserLogin, UserLoginOTP, CResendOtp, AuditLogs } from '../../model/user.model';
import { AuthenticationService } from '../../service/childservice/authenticationService.service';
import { AzureADService } from '../../service/childservice/azureAD.service';
import { AppConfigService, AppConfig } from '../../config/AppConfig.config';
import { GlobalService } from '../../service/global.service';
import { LogService, Log, CustomerSettings, ContinuousService } from '../../service/childservice';



@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html'
})
export class LegalComponent implements OnInit {

  private config: AppConfig;


  constructor() {

  }



  ngOnInit(): void {

  }


}
