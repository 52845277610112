import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable ,  throwError } from 'rxjs';
import { JwtService } from '../jwt.service';
import { catchError } from 'rxjs/operators';
import { API_CONFIG, AppConfig, APP_CONFIG } from '../../config/app.config';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { ResetModel, ResetpwdModel, ResetPassword, AuditLogs } from '../../model/user.model';
import { AppConfigService } from '../../config/AppConfig.config';
import { GlobalService } from '../../service/global.service';

@Injectable()
export class ResetService {

  private config: AppConfig;

  constructor(
    private http: HttpClient, private apiService: ApiService,
    private appConfigService: AppConfigService,
    private globalService: GlobalService
  ) { this.config = appConfigService.appConfig;}


  Reset(emailid: string): Observable<ResetModel> {
    return this.apiService.get(this.config.resetpassword + emailid)
      .pipe(map((data => data)));
  }

  ResetPassword(resetPassword: ResetPassword): Observable<ResetModel> {
    return this.apiService.post(this.config.ResetPwdbyEmail, resetPassword)
      .pipe(map((data => data)));
  }

  //Resetpassword(resetpwd: ResetpwdModel): Observable<ResetModel> {
  //  return this.apiService.post(this.config.ResetPwdbyEmail, resetpwd)
  //    .pipe(map((data => data)));
  //}

  UpdateEmailConfirmationStatus(emailid: string): Observable<ResetModel> {
    return this.apiService.put(this.config.EmailConfirmation + emailid)
      .pipe(map((data => data)));
  }


  logout() {
    var auditlogs = new AuditLogs();
    auditlogs.action = "Logout Status";

    auditlogs.userid = sessionStorage.getItem("Suserid");
    auditlogs.customerid = sessionStorage.getItem("Scustomerid");
    auditlogs.auditlog = "Logged out from IP Address " + sessionStorage.getItem("SIpAddress");;

    this.globalService.PostAuditLogs(auditlogs).subscribe(result => {

    });
    sessionStorage.clear();
    return this.apiService.get(this.config.logout)
      .pipe(map((data => data)));
  }

  
}
